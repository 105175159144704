.privacy-policy-wrap{
    padding: 0px;
    overflow-x: hidden;
    padding-left: 5%;
    margin: 0 auto;
}
.privacy-policy-wrap .app-title{
    margin-top:70px !important;
}
.privacy-policy-inner-wrap{
    padding: 40px;
    padding-top: 0;
}
.privacy-policy-col{
    padding-left: 0 !important;
    padding-top:0;
}
.privacy-policy-wrap ul{
    list-style: none;
    width: 80%;
    margin: 0;
    padding: 0;
}
.privacy-policy-wrap ul li{
    margin-top: 25px;
    font-size: 1vw;
}
.privacy-policy-wrap h3{
    margin-top: 55px;
    font-size: 1.9vw;
    color: #fb7569;
}
.first-title{
    margin-top: 0px !important;
}

.privacy-policy-wrap .contact-links{
    margin: 0 auto !important;
    margin-top: 50px !important;
    width: 80% !important;
    text-align: center;
}

.privacy-policy-wrap .contact-links a{
    color:#fb867c;
    display: inline-block;
    margin: 0px 15px;
    margin-right: 15px;
    text-transform: uppercase;
}
.privacy-policy-wrap .col-12,.privacy-policy-wrap .col-lg-12{
    padding: 0 !important;
}

@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {
    .privacy-policy-wrap .app-title{
        font-size: 4vw;
        margin-top: 100px !important;
    }
    .privacy-policy-wrap h3{
        font-size: 3.2vw;
    }
    .privacy-policy-wrap ul li{
        font-size: 2.5vw;
    }
    .privacy-policy-wrap ul{
        width: 96%;
    }
    .last-update{
        font-size: 2.8vw;
    }
}

@media only screen and (max-width: 768px) {
    .privacy-policy-wrap{
        margin-top: 50px;
    }
    .privacy-policy-inner-wrap{
        padding: 40px 20px;
        padding-top: 0;
    }
    .privacy-policy-wrap ul{
        width: 100%;
    }
    .privacy-policy-wrap ul li{
        font-size: 3vw;
    }
    .privacy-policy-wrap h3{
        font-size: 3.9vw;
    }
    .privacy-policy-wrap h3:first-child{
        padding-top: 0;
        margin-top: 0;
    }
    .privacy-policy-wrap .contact-links a{
        font-size: 3vw;
        text-align: center;
        display: block;
        margin: 0 auto;
        margin-top: 35px;
    }
    .privacy-policy-wrap .app-title{
        text-transform: uppercase;
        font-size: 5vw;
        text-align: center;
        width: 90%;
        margin-top: 50px !important;
        margin-bottom: 30px !important;
        padding-left:0;
        padding-right:0;
    }
    .policy-padding-dummy{
        min-height: 100px;
        width: 100%;
    }
}

@media only screen and (max-width: 420px) {
    .privacy-policy-wrap ul li{
        font-size: 4vw;
    }
    .privacy-policy-wrap h3{
        font-size: 4.9vw;
    }
    .privacy-policy-wrap .contact-links a{
        font-size: 3.5vw;
    }
    .privacy-policy-wrap .app-title{
        font-size: 6vw;
        margin-top: 0px !important;
        margin-bottom: 10px !important;
    }
}

@media only screen and (max-width: 320px){
    .privacy-policy-wrap{
        padding-left: 1%;
    }
    .privacy-policy-wrap .app-title{
        padding: 20px 0;
    }
    .privacy-policy-wrap h3{
        margin-top: 15px;
    }
}