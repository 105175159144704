.header-row{
    padding: 10px 6%;
    font-size: 5vw;
    background: #fc7569;
    color: white;
    display: none !important;
    position: fixed;
    width: 100%;
    z-index: 100;
}
.logo-name{
    float: right;
}
.menu-icon{
    font-size: 6vw;
}

@media only screen and (max-width: 768px) {
    .header-row{
        display: flex !important;
    }
}