.download-app-wrap {
    background-color: #fffaf6;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    padding: 0;
}

.da-page-inner{
    padding: 0 3%;
    position: relative;
}

.da-img-web{
    width: 100%;
}

.da-buttons-wrap{
    width: 100%;
    margin-top: 50px;
    margin-left: 3%;
}
.da-buttons-wrap div{
    width: 100%;
}
.da-buttons-wrap img{
    cursor: pointer;
    display: block;
    width: 35%;
    margin-bottom: 50px;
    -webkit-transition: all ease-out .5s;
    -moz-transition: all ease-out .5s;
    -o-transition: all ease-out .5s;
    transition: all ease-out .5s;
}
.da-buttons-wrap img:hover{
    transform: scale(1.1);
    opacity: .8;
}

.da-welcome-title {
    font-size: 3.2vw;
    margin-left: 3%;
}
.da-info{
    font-size: 1.2vw;
    margin-top: 40px;
    margin-left:3%;
    width: 90%;
}
.da-info div{
    margin-top: 30px;
}
.da-info a,.da-info a:hover{
    color:#fb867c;
}
.under-construction-pop{
    width: 40%;
    padding: 20px;
    background: #ffffff;
    border-radius: 5px;
    min-height: 100px;
    z-index: 100001;
    position: fixed;
}
.construction-img{
    display: block;
    width: 95%;
    margin: 20px auto;
}
.close-construction-pop{
    width: 40px;
    height: 40px;
    position: absolute;
    top: -15px;
    right: -15px;
    cursor: pointer;
}
.construction-desc{
    width: 80%;
    display: block;
    margin: 0 auto;
    font-size: .9vw;
    color:#333333;
}
.under-construction-pop .app-button{
    margin: 20px auto;
    margin-top: 35px !important;
}
.da-img-mob{
    display: none;
}

@media only screen and (max-width: 1700px) {

}

@media only screen and (max-width: 1500px) {
    .download-app-button {
        padding: 23px 5px;
    }
}

@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {
    .download-app-wrap {
        margin-top: 46px;
        background-size: 135% auto;
    }
    .da-welcome-title{
        font-size: 6vw;
    }
    .da-info {
        font-size: 3.4vw;
        left: 6%;
        width: 80%;
    }
    .download-app-button {
        width: 48%;
        font-size: 2.4vw;
        min-height: 90px;
        background-size: 12% auto;
    }
    .da-buttons-wrap{
        width: 90%;
    }
    .download-app-button.dab-ios{

    }
    .download-app-button span{
        margin-left: 15%;
    }
}

@media only screen and (max-width: 768px) {
    .da-img-mob{
        display: block;
    }
    .da-img-web{
        display: none;
    }
    .da-buttons-wrap div{
        width: 50%;
        float: left;
    }
    .da-buttons-wrap img{
        display: block;
        margin: 0 auto;
        width: 80%;
    }
    .download-app-wrap {
        margin-top: 46px;
        padding-bottom: 100px;
    }
    .da-welcome-title{
        font-size: 7vw;
        left:8%;
    }
    .da-info{
        font-size: 3.6vw;
        left:8%;
        width: 95%;
    }
    .da-info div{
        margin-top: 13px;
    }
    .da-buttons-wrap{
        width: 100%;
    }
    .under-construction-pop{
        width: 90%;
    }
    .construction-desc{
        width: 90%;
        font-size: 4vw;
    }
}

@media only screen and (max-width: 540px) {
    .download-app-button{
        min-height: 50px;
        bottom: 4%;
    }
}

@media only screen and (max-width: 420px) {
    .download-app-button{
        font-size: 2.7vw;
        min-height: 40px;
        bottom: 4%;
    }
    .da-welcome-title{
        font-size: 8.2vw;
        top:20%;
    }
    .da-info{
        font-size: 4.6vw;
        top:28%;
    }

    .under-construction-pop{
        width: 90%;
    }
    .construction-desc{
        font-size: 4vw;
    }
}

@media only screen and (max-width: 320px) {
    .download-app-wrap{
        margin-top: 40px;
    }
    .download-app-button{
        min-height: 30px;
        bottom: 5%;
    }
}

@media only screen and (max-width: 280px) {
    .da-info{
        font-size: 5vw;
    }
}
