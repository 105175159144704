@import url(https://fonts.googleapis.com/css?family=Comfortaa);
html,body{
    overflow: hidden !important;
}
*{
    font-family:Comfortaa;
}
.row{
    margin: 0 !important;
}
.site-body{
    background: #fffaf6;
    padding: 0 !important;
}
.site-storeis-wrap{
    padding: 50px 6%;
}
.center{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    text-align: center;
}
.App{
    position: relative;
}
.app-page{
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    padding: 40px;
}
.app-page::-webkit-scrollbar {
    width: 10px;
    cursor:pointer;
}
.app-page::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}
.app-page::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #fb7569;
}
.app-page::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255,0,0,0.4);
}

.app-title{
    text-transform: uppercase;
    font-size: 2.3vw;
    text-align: left;
    width: 80%;
    padding: 40px;
    padding-left: 0;
    color: #000;
    margin: 0 !important;
}

.loading-overlay{
    position:fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    display: none;
    background: rgba(0,0,0,.1);
}
.loading-overlay.show{
    display: block;
}
.loading-overlay img{
    width: 4%;
    background: transparent;
}
.loading-overlay.dark{
    background: rgba(0,0,0,.5);
}

.app-button{
    color:white;
    background: #fb867c;
    border-radius: 100px;
    text-align: center;
    padding: 10px 30px;
    font-size: 1vw;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.app-button:hover{
    color: white;
    text-decoration: none;
}

.slick-slider{
    width: 100%;
}
.slick-slide {
    outline: none;
}

.screen-test-div {
    position: absolute;
    background: transparent;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -99999999999999;
    color: transparent;
}
.library-alpha-notice{
    position: absolute;
    background: darkred;
    opacity: .7;
    color: #ffffff;
    font-size: .7vw;
    padding: 10px 0;
    text-align: center;
    top:0;
    left: 0;
    width: 100%;
    z-index: 100;
}
.lib-page-wrap{
    padding-top: 20px;
}
.hide-library-alpha-notice{
    color: darkred;
    background: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 100px;
    font-size: .8vw;
    left:85%;
    cursor: pointer;
}
.hide-library-alpha-notice span{
    width: 90%;
}
.site-stories-wrap{
    padding-top: 30px !important;
}
.lib-page-wrap{
    padding-top: 40px !important;
}

@media only screen and (max-width: 768px) {
    .app-title{
        margin: 0 auto !important;
    }
    .app-page{
        margin-top: 47px;
    }
    .app-button{
        font-size: 3vw;
    }
    .library-alpha-notice{
        top:47px;
        font-size: 2.5vw;
        padding: 10px 5%;
        padding-right: 15%;
        text-align: left;
    }
    .hide-library-alpha-notice{
        width: 25px;
        height: 25px;
        font-size: 3.6vw;
        left: 93%;
    }
    .hide-library-alpha-notice span{
        top: 45%;
        width: 80%;
    }
    .site-stories-wrap{
        padding-top: 40px !important;
    }
    .lib-page-wrap{
        padding-top: 80px !important;
    }

    .app-page::-webkit-scrollbar {
        width: 0;
        cursor:unset;
    }
    .app-page::-webkit-scrollbar-track {
        -webkit-box-shadow: unset;
        border-radius: unset;
    }
    .app-page::-webkit-scrollbar-thumb {
        border-radius: unset;
        background: unset;
    }
    .app-page::-webkit-scrollbar-thumb:window-inactive {
        background: unset;
    }
}


.contact-page-wrap{
    padding: 0;
    overflow-x: hidden;
}
.contact-page-wrap .app-title{
    padding-left: 10px !important;
    margin-top:70px !important;
}
.login{
    top: 50%;
    width: 96%;
    display: table;
    margin: 50px auto;
    margin-top:0;
    background: #fcf0e8;
    border-radius: 4px;
}

.inner-contact-form-wrap{
    width: 90%;
    margin: 0 auto !important;
}

fieldset{
    border: 0;
}

.contact-input {
    position: relative;
    width: 100%;
    margin: 15px auto;
}

.contact-input span{
    position: absolute;
    display: block;
    color: #baaaa4;
    left: 10px;
    top: 8px;
    font-size: 1.2vw;
    z-index: 10;
    width: 3.5%;
}
.contact-input span img{
    width: 100%;
}

.contact-input input,.contact-input textarea{
    width: 100%;
    padding: 10px 5px 10px 40px;
    display: block;
    border: 1px solid #fdf0e9;
    border-radius: 16px;
    transition: 0.2s ease-out;
    color: #fb867c;
    font-size: 1.2vw;
    background: #fdf0e9;
    outline: 0;
}

.contact-input input::-webkit-input-placeholder,.contact-input textarea::-webkit-input-placeholder {color: #baaaa4;}
.contact-input input::-moz-placeholder,.contact-input textarea::-moz-placeholder  {color: #baaaa4;}
.contact-input input:-ms-input-placeholder,.contact-input textarea:-ms-input-placeholder  { color: #baaaa4;}
.contact-input input:-moz-placeholder,.contact-input textarea:-moz-placeholder  {color: #baaaa4;}

.contact-input input:focus,.contact-input textarea:focus{
    padding: 10px 5px 10px 10px;
    border-color: #FF7052;
}

.contact-input:focus{
    padding: 10px 5px 10px 10px;
    outline: 0;
    border-color: #FF7052;
}
.contact-input:focus-within span{
    display: none;
}

.contact-input label{
    font-size: 3vw;
}

.about-me-counter{
    font-size: 2.5vw;
    float: right;
    margin-top:5px;
}

.contact-input textarea{
    resize: none;
    min-height: 400px;
}
.submit-contact{
    display: block;
    padding: 5px 5px;
    width: 30%;
    text-align: center;
    background: #fb867c;
    color: #fdf2ec;
    border-radius: 100px;
    box-shadow: none;
    font-size: 1.2vw;
    border: 0;
    outline: 0 !important;
    float: right;
    margin-top: 15px;
    margin-right: 0%;
}

#send-form-success{
    text-align: center;
    font-size: 5vw;
    position: absolute;
    top:50%;
    left:50%;
    width:80%;
    transform:translate(-50%,-50%);
}

.contact-image-col img{
    width: 80%;
    margin: 0 auto;
}

.contact-error{
    font-size:1.1vw;
    color:red;
    min-height: 25px;
}
.contact-error.success{
    color:green;
}
.grecaptcha-badge{
    bottom: 0 !important;
    right: unset !important;
    left:2% !important;
}

@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {
    .contact-image-col{
        display: none;
    }
    .contact-form-col{
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    .contact-page-wrap .app-title{
        font-size: 5vw;
        margin-top: 100px !important;
    }
    .contact-input input, .contact-input textarea{
        font-size: 3.6vw;
        padding: 10px 5px 10px 80px;
    }
    .contact-input span{
        width: 5.5%;
    }
    .submit-contact{
        font-size: 4vw;
    }
}

@media only screen and (max-width: 768px) {
    .contact-page-wrap{
        padding-bottom: 100px;
    }
    .contact-input input,.contact-input textarea{
        font-size: 4.2vw;
    }
    .contact-input textarea{
        min-height:300px;
    }
    .contact-input input, .contact-input textarea{
        padding: 10px 5px 10px 60px;
    }
    .contact-input span{
        font-size: 4.2vw;
        width: 5%;
    }
    .contact-input span img{
        left: 0;
    }
    .submit-contact{
        font-size: 4.2vw;
    }
    .inner-contact-form-wrap{
        position: static;
        transform: translate(0,0);
        margin: 0 auto !important;
    }
    .inner-contact-form-wrap img{
        position: relative;
        left:-17%;
        top:-15%;
        width: 75%;
    }
    .contact-page-wrap .app-title{
        text-transform: uppercase;
        font-size: 6vw;
        text-align: center;
        width: 80%;
        margin: 0px auto !important;
        margin-top: 10px !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 10px !important;
    }
    .contact-image-col{
        display: none;
    }
    .contact-error{
        font-size: 4vw;
    }
    .contact-image-col{
        display: none;
    }
    .contact-form-col{
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}

@media only screen and (max-width: 420px) {
    .contact-input input,.contact-input textarea{
        font-size: 4.2vw;
    }
    .contact-input span{
        font-size: 4.2vw;
        width: 8%;
    }
    .submit-contact{
        font-size: 4.2vw;
        margin-top: 0;
    }
    .contact-page-wrap .app-title{
        font-size: 6vw;
        margin-top: 0 !important;
        padding-top: 10px !important;
    }
    .contact-error{
        font-size: 4vw;
    }
    .contact-input textarea{
        min-height:180px;
    }
    .contact-input:last-child{
        margin-bottom: 0;
    }
}
.download-app-wrap {
    background-color: #fffaf6;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    padding: 0;
}

.da-page-inner{
    padding: 0 3%;
    position: relative;
}

.da-img-web{
    width: 100%;
}

.da-buttons-wrap{
    width: 100%;
    margin-top: 50px;
    margin-left: 3%;
}
.da-buttons-wrap div{
    width: 100%;
}
.da-buttons-wrap img{
    cursor: pointer;
    display: block;
    width: 35%;
    margin-bottom: 50px;
    transition: all ease-out .5s;
}
.da-buttons-wrap img:hover{
    transform: scale(1.1);
    opacity: .8;
}

.da-welcome-title {
    font-size: 3.2vw;
    margin-left: 3%;
}
.da-info{
    font-size: 1.2vw;
    margin-top: 40px;
    margin-left:3%;
    width: 90%;
}
.da-info div{
    margin-top: 30px;
}
.da-info a,.da-info a:hover{
    color:#fb867c;
}
.under-construction-pop{
    width: 40%;
    padding: 20px;
    background: #ffffff;
    border-radius: 5px;
    min-height: 100px;
    z-index: 100001;
    position: fixed;
}
.construction-img{
    display: block;
    width: 95%;
    margin: 20px auto;
}
.close-construction-pop{
    width: 40px;
    height: 40px;
    position: absolute;
    top: -15px;
    right: -15px;
    cursor: pointer;
}
.construction-desc{
    width: 80%;
    display: block;
    margin: 0 auto;
    font-size: .9vw;
    color:#333333;
}
.under-construction-pop .app-button{
    margin: 20px auto;
    margin-top: 35px !important;
}
.da-img-mob{
    display: none;
}

@media only screen and (max-width: 1700px) {

}

@media only screen and (max-width: 1500px) {
    .download-app-button {
        padding: 23px 5px;
    }
}

@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {
    .download-app-wrap {
        margin-top: 46px;
        background-size: 135% auto;
    }
    .da-welcome-title{
        font-size: 6vw;
    }
    .da-info {
        font-size: 3.4vw;
        left: 6%;
        width: 80%;
    }
    .download-app-button {
        width: 48%;
        font-size: 2.4vw;
        min-height: 90px;
        background-size: 12% auto;
    }
    .da-buttons-wrap{
        width: 90%;
    }
    .download-app-button.dab-ios{

    }
    .download-app-button span{
        margin-left: 15%;
    }
}

@media only screen and (max-width: 768px) {
    .da-img-mob{
        display: block;
    }
    .da-img-web{
        display: none;
    }
    .da-buttons-wrap div{
        width: 50%;
        float: left;
    }
    .da-buttons-wrap img{
        display: block;
        margin: 0 auto;
        width: 80%;
    }
    .download-app-wrap {
        margin-top: 46px;
        padding-bottom: 100px;
    }
    .da-welcome-title{
        font-size: 7vw;
        left:8%;
    }
    .da-info{
        font-size: 3.6vw;
        left:8%;
        width: 95%;
    }
    .da-info div{
        margin-top: 13px;
    }
    .da-buttons-wrap{
        width: 100%;
    }
    .under-construction-pop{
        width: 90%;
    }
    .construction-desc{
        width: 90%;
        font-size: 4vw;
    }
}

@media only screen and (max-width: 540px) {
    .download-app-button{
        min-height: 50px;
        bottom: 4%;
    }
}

@media only screen and (max-width: 420px) {
    .download-app-button{
        font-size: 2.7vw;
        min-height: 40px;
        bottom: 4%;
    }
    .da-welcome-title{
        font-size: 8.2vw;
        top:20%;
    }
    .da-info{
        font-size: 4.6vw;
        top:28%;
    }

    .under-construction-pop{
        width: 90%;
    }
    .construction-desc{
        font-size: 4vw;
    }
}

@media only screen and (max-width: 320px) {
    .download-app-wrap{
        margin-top: 40px;
    }
    .download-app-button{
        min-height: 30px;
        bottom: 5%;
    }
}

@media only screen and (max-width: 280px) {
    .da-info{
        font-size: 5vw;
    }
}

.about-wrap{
    overflow-x: hidden;
    padding: 0 0 0 0;
    margin: 0 auto;
    padding-bottom: 100px;
}
.about-wrap a{
    color:#fb867c;
}
.about-wrap a:hover{
    color:#fb867c;
}
.about-wrap .app-title{
    padding-left: 0 !important;
    margin-top:0 !important;
    padding-top: 0 !important;
    margin-bottom: 50px;
    color:#fb7569;
    width: 100%;
}
.about-wrap h3{
    font-size: 1.9vw;
    color: #fb7569;
}
.about-section{
    padding: 0 0 0 5%;
    padding-top: 80px;
    font-size: 1.2vw;
    position: relative;
    overflow: visible;
}
.about-section-bg-1{
    left:30%;
    top:50%;
    width: 60%;
}
.about-section-bg-2{
    left:70%;
    top:50%;
    width: 100% !important;
}
.about-section-bg-3{
    left:30%;
    top:50%;
    width: 100% !important;
}

.about-infographic{
    width: 80%;
    margin: 0 auto;
    margin-bottom: 50px;
}
.about-infographic img{
    width: 100%;
}

.about-cont-1{
    padding-right: 17%;
}

.about-vid{
    position: relative;
    width: 82%;
    margin: 0 auto;
}
.about-slider{
    width: 62%;
    margin: 0 auto;
    position: relative;
}
.about-vid-pc{
    width: 100%;
}
.about-vid-play{
    top:45%;
    width: 25%;
    cursor: pointer;
    transition: all ease-out .5s;
    transform-origin: 0 0;
}
.about-vid-play:hover{
    width: 30%;
    opacity: .8;
}

.about-wrap .app-title.about-title-2{
    margin-top: 50px !important;
}

.about-wrap .app-title.about-story-modes-title{
    text-align: center;
    margin: 50px auto !important;
    margin-top: 150px !important;
}
.about-story-section img{
    display: block;
    margin: 0 auto;
    width: 60%;
}
.about-story-section{
    margin-bottom: 80px !important;
}
.about-story-section ul{
    list-style: none;
    margin-top: 30px;
    padding-left: 0;
    width: 90%;
}
.about-story-section ul li{
    margin-bottom: 30px;
}
.about-story-section-cont{
    width: 70%;
    text-align: left !important;
}

.creators-section{
    margin-top: 250px !important;
}
.creators-section h2{
    position: relative;
    top:150px;
    left:150px;
}
.creators-section img{
    width: 40%;
    margin: 0 auto;
    display: block;
}
.creators-section p{
    width: 80%;
    margin: 0 auto;
}

.about-slider-arrow{
    width: 4%;
    cursor: pointer;
}
.asa-right{
    left:104%;
}
.asa-left{
    left: -4%;
}
.about-infographic-text{
    position: absolute;
    width: 16%;
    text-align: center;
    font-size: 1vw;
}
.ait-1{
    left:10%;
    top:45%;
}
.ait-2{
    left:38%;
    top:45%;
}
.ait-3{
    left:68%;
    top:45%;
}
.ait-4{
    left:25%;
    top:83%;
}
.ait-5{
    left:55%;
    top:83%;
}
.about-infographic-mob{
    display: none;
}

@media only screen and (max-width: 1500px) {
    .creators-section h2 {
        top: 100px;
        left: 100px;
    }
    .creators-section{
        margin-top: 150px !important;
    }
    .about-story-section{
        margin-bottom: 60px !important;
    }
    .about-wrap .app-title.about-story-modes-title{
        margin-top: 100px !important;
    }
}

@media only screen and (max-width: 1366px) {
    .about-wrap .app-title.about-story-modes-title{
        margin-top: 70px !important;
        margin-bottom: 20px !important;
    }
}

@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {
    .about-wrap{
        padding-top: 100px !important;
    }
    .about-wrap .app-title{
        font-size: 4vw;
    }
    .about-section{
        font-size: 2vw;
    }
    .about-cont-1{
        padding-right: 0;
    }
    .about-vid{
        width: 100%;
    }
    .about-slider{
        width: 90%;
        margin-top: 50px;
    }
    .about-infographic{
        width: 90%;
    }
    .about-wrap .app-title.about-story-modes-title{
        margin-top: 100px !important;
    }
    .about-wrap h3{
        font-size: 3.2vw;
    }
    .about-story-section-cont{
        width: 90%;
    }
    .about-story-section img{
        width: 80%;
    }
    .creators-section img{
        width: 60%;
    }
    .creators-section h2 {
        top: 100px;
        left: 0px;
    }
    .creators-section p{
        width: 90%;
    }
    .about-infographic-text {
        width: 25%;
        font-size: 2vw;
    }
    .ait-1 {
        left: 1%;
        top: 47%;
    }
    .ait-2 {
        left: 33%;
        top: 47%;
    }
    .ait-3 {
        left: 66%;
        top: 47%;
    }
    .ait-4 {
        left: 20%;
        top: 83%;
    }
    .ait-5 {
        left: 52%;
        top: 83%;
    }
}

@media only screen and (max-width: 768px) {
    .about-wrap{
        padding-top: 100px !important;
    }
    .about-wrap .app-title{
        font-size: 4vw;
    }
    .about-section{
        font-size: 2vw;
    }
    .about-cont-1{
        padding-right: 0;
    }
    .about-vid{
        width: 100%;
    }
    .about-slider{
        width: 90%;
        margin-top: 50px;
    }
    .about-infographic{
        width: 90%;
    }
    .about-wrap .app-title.about-story-modes-title{
        margin-top: 100px !important;
    }
    .about-wrap h3{
        font-size: 3.2vw;
    }
    .about-story-section-cont{
        width: 90%;
    }
    .about-story-section img{
        width: 80%;
    }
    .creators-section img{
        width: 60%;
    }
    .creators-section h2 {
        top: 100px;
        left: 0px;
    }
    .creators-section p{
        width: 90%;
    }
    .about-infographic-text {
        width: 25%;
        font-size: 2vw;
    }
    .ait-1 {
        left: 1%;
        top: 47%;
    }
    .ait-2 {
        left: 33%;
        top: 47%;
    }
    .ait-3 {
        left: 66%;
        top: 47%;
    }
    .ait-4 {
        left: 20%;
        top: 83%;
    }
    .ait-5 {
        left: 52%;
        top: 83%;
    }
}

@media only screen and (max-width: 540px) {
    .about-wrap{
        padding-top: 130px !important;
    }
    .about-wrap .app-title{
        font-size: 5.5vw;
        text-align: center;
        padding-bottom: 30px;
        padding-right: 0 !important;
    }
    .about-section{
        font-size: 4vw;
        padding-top: 0;
    }
    .about-cont-1{
        padding-right: 0;
        margin-bottom: 50px;
    }
    .about-vid{
        width: 100%;
    }
    .about-slider{
        width: 90%;
        margin-top: 50px;
    }
    .about-infographic{
        width: 100%;
    }
    .about-wrap .app-title.about-story-modes-title{
        margin-top: 40px !important;
        margin-bottom: 20px !important;
        padding-bottom: 0 !important;
    }
    .about-wrap h3{
        font-size: 5vw;
        text-align: center;
    }
    .about-story-section ul{
        margin: 0 auto;
        margin-top: 30px;
    }
    .about-story-section-cont{
        width: 100%;
        padding-top: 40px;
        position: static !important;
        transform: translate(0,0);
    }
    .about-story-section{
        margin-bottom: 0px !important;
    }
    .about-story-section img{
        width: 80%;
    }
    .creators-section img{
        width: 60%;
    }
    .creators-section h2 {
        top: 0;
        left: 0;
    }
    .creators-section p{
        width: 90%;
    }
    .creators-section img{
        width: 90%;
    }
    .creators-section p{
        width: 100%;
        margin-top: 20px;
    }
    .creators-section{
        margin-top: 50px !important;
    }
    .about-section{
        padding-left: 0 !important;
    }
    .about-infographic-web{
        display: none;
    }
    .about-infographic-mob{
        display: block;
    }
    .about-infographic-text {
        width: 43%;
        font-size: 3.5vw;
    }
    .ait-1{
        top: 32%;
        left: 5%;
    }
    .ait-2{
        top: 32%;
        left: 50%;
    }
    .ait-3{
        top: 57%;
        left: 30%;
    }
    .ait-4{
        top: 85%;
        left: 5%;
    }
    .ait-5{
        top: 85%;
        left: 50%;
    }
}

@media only screen and (max-width: 420px) {
    .about-wrap{
        padding-top: 100px !important;
    }
}

@media only screen and (max-width: 280px) {
    .about-wrap .app-title.about-story-modes-title{
        margin-top: 0 !important;
    }
    .about-wrap{
        padding-top: 80px !important;
    }
}


.app-sidebar-col{
    padding: 0 !important;
}
.main-sidebar-col{
    padding: 0 !important;
}
.main-sidebar{
    background:#fb7569;
    color:white;
    height: 100vh;
    position: relative;
}
.main-sidebar h1 img{
    width: 90%;
    display: block;
    margin:0 auto;
}
.main-sidebar h1{
    text-align: center;
    font-size: 2vw;
    margin: 20px 0;
    margin-top: 30px;
    margin-bottom: 2px;
    display: block;
    text-align: center;
}
.side-icon-col{
    color:#fdd35b;
    font-size: 2vw;
    position: relative;
}
.side-icon-col svg{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}
.menu-row{
    padding: 10px;
    color:white;
    cursor: pointer;
    min-height: 50px;
}
.menu-row:hover,.menu-row.active{
    background: white;
    color: black;
}
.logo-menu-row.active{
    background: transparent;
}
.logo-menu-row:hover{
    background: transparent;
}
.menu-row:hover a{
    color: black;
}
.menu-row span{
    position: absolute;
    top:50%;
    transform: translate(0,-50%);
    left:5%;
    font-size: .9vw;
}
.menu-row a{
    color:white;
}
.menu-row a:hover{
    text-decoration: none;
}
.side-link-col{
    position: relative;
}

.site-subtitle{
    font-size: .9vw;
    text-align: center;
    margin-bottom: 35px;
}
.site-subtitle span{
    color: #fdd35b;
}

.close-app-sidebar{
    display: none;
}
.menu-row .col-2{
    padding: 0;
}
.menu-row img{
    width: 90%;
    position: relative;
    top:-5px;
}


@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {
    .app-sidebar-col.col-md-3{
        max-width: 0;
        flex:0 0
    }
    .site-body.col-md-9{
        max-width: 100%;
        flex:0 0 100%
    }
    .main-sidebar{
        position: fixed !important;
        left: 0;
        top: 0;
        transform: translate3d(-100vw, 0, 0);
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        margin: 0 !important;
    }
    .main-sidebar.active{
        -webkit-animation: slide-in-left .5s forwards;
    }

    .main-sidebar.hidden{
        -webkit-animation: slide-out-left .5s forwards;
    }

    .close-app-sidebar{
        display: block;
        position: absolute;
        top:3%;
        right:5%;
        font-size: 6vw;
        font-weight: 500;
    }
    .header-row.row {
        display: flex !important;
    }
    .menu-row span{
        font-size: 5vw;
    }
    .main-sidebar h1{
        font-size: 9vw;
    }
    .main-sidebar h1 img{
        width: 50%;
    }
    .menu-row{
        padding: 48px 10px;
        min-height:65px;
    }
    .side-icon-col{
        font-size: 7vw;
    }

    .site-subtitle{
        font-size: 4vw;
    }
    .menu-row img{
        width: 50%;
    }
    .site-subtitle{
        display: none;
    }
    .menu-row{
        padding: 10px 10px;
    }
    .main-sidebar h1 img{
        width: 40%;
    }
    .menu-row span{
        left: 2%;
    }
}

@media only screen and (max-width: 768px) {
    .app-sidebar-col.col-md-3{
        max-width: 0;
        flex:0 0
    }
    .site-body.col-md-9{
        max-width: 100%;
        flex:0 0 100%
    }
    .main-sidebar{
        position: fixed !important;
        left: 0;
        top: 0;
        transform: translate3d(-100vw, 0, 0);
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        margin: 0 !important;
    }


    .main-sidebar h1{
        font-size: 9vw;
    }
    .main-sidebar h1 img{
        width: 50%;
    }

    .menu-row{
        padding: 48px 10px;
        min-height:65px;
    }

    .menu-row span{
        font-size: 4vw;
        left:10%;
    }

    .side-icon-col{
        font-size: 7vw;
    }

    .site-subtitle{
        font-size: 4vw;
    }

    .main-sidebar.active{
        -webkit-animation: slide-in-left .5s forwards;
    }

    .main-sidebar.hidden{
        -webkit-animation: slide-out-left .5s forwards;
    }

    .close-app-sidebar{
        display: block;
        position: absolute;
        top:3%;
        right:5%;
        font-size: 6vw;
        font-weight: 500;
    }
    .menu-row img{
        width: 50%;
    }
    .site-subtitle{
        display: none;
    }
    .menu-row{
        padding: 10px 10px;
    }
    .main-sidebar h1 img{
        width: 40%;
    }
    .menu-row span{
        left: 2%;
    }
}

@media only screen and (max-width: 420px) {
    .main-sidebar h1{
        font-size: 9vw;
    }
    .menu-row span{
        font-size: 5vw;
    }
    .side-icon-col{
        font-size: 9vw;
    }
    .site-subtitle{
        font-size: 4vw;
    }
    .close-app-sidebar{
        font-size: 6vw;
    }
    .menu-row{
        padding: 15px 10px;
    }
    .menu-row img{
        width: 80%;
    }
}

@media only screen and (max-width: 320px){
    .menu-row {
        padding: 15px 10px;
    }
}

@-webkit-keyframes slide-in-left {
    from {
        transform: translate3d(-100vw, 0, 0);
    }
    to {
        transform: translate3d(0vw, 0, 0);
    }
}

@keyframes slide-in-left {
    from {
        transform: translate3d(-100vw, 0, 0);
    }
    to {
        transform: translate3d(0vw, 0, 0);
    }
}

@-webkit-keyframes slide-out-left {
    from {
        transform: translate3d(0vw, 0, 0);
    }
    to {
        transform: translate3d(-100vw, 0, 0);
    }
}

@keyframes slide-out-left {
    from {
        transform: translate3d(0vw, 0, 0);
    }
    to {
        transform: translate3d(-100vw, 0, 0);
    }
}
.download-app-banner-wrap{
    position: absolute;
    width: 100%;
    bottom: -20%;
    left:0%;
    background: #fdd35b;
    -webkit-animation: slide-in-bottom .5s forwards;
    -webkit-animation-delay: .5s;
            animation-delay: .5s;
}

@media only screen and (max-width: 768px) {

}

@-webkit-keyframes slide-in-bottom {
    from {
        bottom: -20%;
    }
    to {
        bottom: 0%;
    }
}

@keyframes slide-in-bottom {
    from {
        bottom: -20%;
    }
    to {
        bottom: 0%;
    }
}

@-webkit-keyframes slide-in-bottom-mob {
    from {
        bottom: -25%;
    }
    to {
        bottom: 0%;
    }
}

@keyframes slide-in-bottom-mob {
    from {
        bottom: -25%;
    }
    to {
        bottom: 0%;
    }
}
.home-stories-row{
    margin-top: 50px !important;
}
.home-stories-row h2{
    font-size: 1.4vw;
    margin-bottom: 15px;
    text-transform: uppercase;
    color:#fb7669;
}
.story-see-all{
    float: right;
    font-size: 1vw;
    color:#fb7669;
}
.slick-dots{
    padding-top: 20px !important;
}
.slick-dots li{
    width: 1.3vw !important;
}
.slick-dots li button:before {
    font-size: .9vw !important;
    color:#fb7669 !important;
}
.slick-dots li.slick-active button:before{
    color:#ffce00 !important;
}

.stories-slider-row{
    position: relative;
}
.slider-arrow{
    width: 1.5%;
    cursor: pointer;
    top:-4%;
}
.slider-arrow-left{
    left:92%;
}
.slider-arrow-right{
    left:98%;
}
.story-sliders-col{
    padding: 0 !important;
}
.stories-slider-row .slick-slide .col-12{
    padding: 8px;
}

@media only screen and (max-width: 768px) {
    .story-see-all{
        font-size: 2vw;
    }
    .slick-dots li{
        width: 1.6vw !important;
    }
    .slick-dots li button:before {
        font-size: 2.3vw !important;
        color:#fb7669 !important;
    }
    .stories-slider-row .slick-slide .col-12{
        padding: 5px;
    }
    .home-stories-row .col-9{
        padding-left: 2px;
    }
    .slider-arrow{
        width: 2.5%;
        top:-6%;
    }
    .slider-arrow-left{
        left: 90%;
    }
}

@media only screen and (max-width: 420px) {
    .story-see-all{
        font-size: 2vw;
    }
    .slick-dots li{
        width: 1.6vw !important;
    }
    .slick-dots li button:before {
        font-size: 2.3vw !important;
    }
    .stories-slider-row .slick-slide .col-12{
        padding: 2px;
    }
}
.story-preview-wrap{
    border-radius:5px;
    background: #fdf0e8;
    padding: 15px;
    margin-bottom: 50px;
    position: relative;
}
.story-title,.story-preview-results-title{
    font-size: 1.1vw;
    margin:10px 0;
    margin-top: 15px;
    color: black;
    min-height: 50px;
}
.story-image{
    width: 100%;
    background: #ffffff;
    position: relative;
}
.story-synopsis-link{
    display: block;
    text-decoration: underline;
    color: #fb7669;
    font-size: .7vw;
    margin-bottom: 10px;
}
.story-by,.story-date,.story-views{
    color: #d0c3bb;
    font-size: .9vw;
}
.story-views{
    margin-top: 5px;
    font-size: .7vw;
}
.story-preview-wrap footer{
    color:#ffd25e;
}
.story-icon{
    margin-left: 5px;
}

.story-footer{
    padding-top: 20px;
}
.story-footer table{
    width: 100%;
    font-size: .7vw;
    color:rgba(115,115,115,.7);
    text-align: left;
}
.story-footer table img{
    width: 45%;
    display: block;
    margin: 0 auto;
}
.story-footer div{
    margin:0 auto;
    text-align: center;
}
.story-synopsis-preview{
    color:rgba(115,115,115,.7);
    height: 100px;
    overflow-y: hidden;
    word-break: break-word;
}
.story-synopsis-link-dummy{
    visibility: hidden;
}
.story-preview-wrap header a{
    text-decoration: none;
    outline: 0;
}
.story-preview-wrap header a img{
    outline: 0;
}
.story-preview-wrap header a:hover .story-title{
    color: #fb7669;
}
.story-item-col{
    position: relative;
}
.story-item-show-more{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fdf0e8;
    border-radius: 5px;
    font-size: 1.3vw;
    color: #fb7669;
    cursor: pointer;
}
.story-item-show-more div{
    width: 96%;
}
.story-item-show-more:hover div{
    opacity: .8;
}
.story-synopsis-link-mob{
    display: none;
}
.story-rect-image,.rect-preview{
    display: none !important;
}
.story-preview-results-wrap{
    display: none;
}
.slide-inner{
    position: relative;
}
.slide-front{
    position: relative;
    /*background: #fcf0e8;*/
}
.slide-back{
    position: absolute;
    width: 98%;
    height: 95%;
    z-index: -1;
    left:0;
    top:0;
    background: #fcf0e8;
    border-radius: 5px;
    /*padding: 10px 5px;*/
    /*padding-bottom: 15px;*/
    font-size: 2.9vw;
    text-align: left;
}
.slide-back-synopsis{
    position: absolute;
    width: 90%;
    left: 5%;
    top:25%;
    max-height: 69%;
    overflow-y: scroll;
    overflow-x: hidden;
    word-break: break-word;
}
.slide-back-title{
    margin: 10px auto;
    margin-top: 25px;
    text-align: center;
    font-size: 3.5vw;
}
.slide-back img{
    position: absolute;
    top:8px;
    right:8px;
    width: 14%;
}
.flip-show{
    -webkit-animation: flip-show .3s linear forwards;
    animation: flip-show .3s linear forwards;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}
.flip-hide{
    -webkit-animation: flip-hide .3s linear forwards;
    animation: flip-hide .3s linear forwards;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}
.slide-back{
    display: none;
}
.story-square-image{
    position: absolute;
    top:0;
    z-index: 100;
}
.square-preview{
    position: relative;
}
.single-story-writer-name{
    color: #fa7669;
    background: #efd0c2;
    border-radius: 100px;
    padding: 4px 8px;
    margin-left: 1%;
    margin-top: 5px;
    font-size: 3vw;
    width: 96% !important;
}
.single-story-writer-name span{
    position: relative;
    top:2px;
    font-size: 3vw;
}
.single-story-writer-name img{
    width: 20px;
    height: 20px;
    position: relative;
    margin-right: 5px;
    display: inline-block !important;
    object-fit: cover;
    border-radius: 100px;
}
.story-writers-wrap{
    width: 100%;
    margin-top: 3px;
}
.single-story-writer-name.hidden{
    opacity: 0;
}

@media only screen and (max-width: 768px) {
    .slide-back{
        display: block;
    }
    .square-preview{
        display: none;
    }
    .library-no-results{
        font-size: 4vw;
    }
    .story-title,.story-preview-results-title{
        font-size: 2.8vw;
        min-height: 50px;
        font-weight: 600;
        padding-left: 1px;
        margin-top: 5px;
    }
    .story-preview-wrap{
        padding: 5px;
        margin-bottom: 10px;
    }
    .library-search-results .story-preview-wrap{
        display: none;
    }
    .story-by,.story-synopsis-preview{
        display: none;
    }
    .story-synopsis-link{
        font-size: 1.5vw;
        padding-left: 5px;
    }
    .story-footer table img{
        width: 60%;
    }
    .story-footer{
        padding-top: 5px;
    }
    .story-footer table{
        font-size: 1.9vw;
    }
    .story-item-show-more{
        font-size: 3.5vw;
    }
    .story-synopsis-link-web{
        display: none;
    }
    .story-synopsis-link-mob{
        display: block;
        font-size: 2.7vw;
        padding-left: 1px;
    }
    .library-search-results .col-3{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .library-search-results .story-item-col{
        background: #fdf0e8;
        border-radius: 5px;
        padding: 5px !important;
        margin-bottom: 15px;
        display: inline-block;
        width: 100%;
    }
    .library-search-results .story-preview-wrap{
        flex: 0 0 33%;
        max-width: 33%;
        float: left;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .library-search-results .story-title,.library-search-results .story-synopsis-link{
        display: none;
    }
    .library-search-results .story-rect-image{
        display: block !important;
        margin-top: 5px;
    }
    .library-search-results .story-square-image{
        display: none;
    }
    .library-search-results .story-preview-results-wrap{
        flex: 0 0 100%;
        max-width: 100%;
        float: left;
        display: block;
    }
    .story-preview-results-wrap .col-4{
        padding: 0;
    }
    .story-preview-results-wrap .col-8{
        padding-right: 0;
    }
    .library-search-results .story-preview-results-title{
        font-size: 4.2vw;
        margin-bottom: 5px;
    }
    .library-search-results .story-footer{
        margin-top: 5px;
    }
    .library-search-results .story-preview-results-synopsis{
        font-size: 3.5vw;
        word-break: break-word;
    }
}

@media only screen and (max-width: 420px) {
    .story-title,.story-preview-results-title{
        font-size: 2.8vw;
        min-height: 30px;
    }
    .story-synopsis-link{
        font-size: 1.5vw;
    }
    .story-footer table{
        font-size: 2.2vw;
    }
    .story-item-show-more{
        font-size: 3.5vw;
    }
    .story-synopsis-link-mob{
        font-size: 2.7vw;
    }
    .library-search-results .story-preview-results-title{
        font-size: 4.2vw;
    }
    .library-search-results .story-preview-results-synopsis{
        font-size: 3.5vw;
    }
    .story-footer table img{
        width: 90%;
    }
    .story-footer{
        padding-top: 0px;
    }
}

@-webkit-keyframes flip-show {
    from { transform: rotateY(-180deg); z-index: -10; }
    to { transform: rotateY(0deg); z-index: 1; }
}

@keyframes flip-show {
    from { transform: rotateY(-180deg); z-index: -10; }
    to { transform: rotateY(0deg); z-index: 1; }
}
@-webkit-keyframes flip-hide {
    from { transform: rotateY(0deg); }
    to { transform: rotateY(-180deg); }
}
@keyframes flip-hide {
    from { transform: rotateY(0deg); }
    to { transform: rotateY(-180deg); }
}

.site-stories-wrap{
    padding: 0 5% 0 5%;
    margin: 0 auto;
}
.site-stories-wrap .app-title{
    padding-bottom: 0;
    padding-left: 10px;
}
.lib-title{
    width: 100%;
}
.lib-inner-title{
    display: inline-block;
    width: 100%;
}
.lib-inner-title div{
    display: block;
    float: left;
}
.lib-inner-title input{
    display: block;
    float: right;
    width: 30%;
    border-radius: 100px;
    font-size: 1.1vw;
    border: 0;
    outline: 0;
    padding: 10px 20px;
    background-color: #fcf0e8;
    color: #333333;
    padding-left: 4%;
    background-size: 6% !important;
    background-position: 3% 50% !important;
    background-repeat: no-repeat !important;
}
.lib-inner-title input::-webkit-input-placeholder {color: rgba(115,115,155,.7);}
.lib-inner-title input::-moz-placeholder {color: rgba(115,115,155,.7);}
.lib-inner-title input:-ms-input-placeholder {color: rgba(115,115,155,.7);}
.lib-inner-title input:-moz-placeholder {color: rgba(115,115,155,.7);}
.library-inner-body{
    position: relative;
}
.library-search-results{
    position: absolute;
    width: 100%;
    top:0;
    left: 0;
    min-height: 100%;
    background: #fffaf6;
}
.library-no-results {
    font-size: 1.5vw;
    margin-top: 300px;
}
.library-results-title{
    font-size: 1.4vw;
    margin-bottom: 15px;
    text-transform: uppercase;
    color:#fb7669;
    margin-left: 1%;
    margin-top: 50px;
}
.library-results-title img{
    width: 2%;
    margin-right: 1%;
    display: block;
    float: right;
    cursor: pointer;
}
.hide-story-sliders{
    display: none;
}
.lib-genres-title{
    font-size: .8vw;
    position: relative;
    top:10px;
}
.lib-mob-title-div{
    display: none !important;
}
.lib-filter-stories{
    display: none;
}
.library-loading-wrap{
    position: fixed;
    width: 83%;
    height: 100%;
    z-index: 101;
    left: 16.5%;
    top: 0;
    background: #fffaf6;
}
.library-loading-web,.library-loading-mob,.mob-lib-results-loading,.web-lib-results-loading{
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    -webkit-animation: blink 3s infinite;
    opacity: 0.5;
}
.results-loading-wrap{
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
    background: #fffaf6;
}
.mob-lib-results-loading,.web-lib-results-loading{
    position: static;
}
.web-lib-results-loading{
    width: 100%;
    position: relative;
    top:-20px;
}
.mob-lib-results-loading{
    top:30px;
    display: none;
}
.library-loading-mob{
    display: none;
}

@media only screen and (max-width: 768px) {
    .site-stories-wrap .app-title{
        margin-top: 30px !important;
    }
    .lib-mob-title-div{
        display: block !important;
        margin-top: 30px !important;
        margin-bottom: 20px !important;
    }
    .lib-web-title-div{
        display: none !important;
    }
    .lib-title{
        font-size: 6vw;
        padding-left: 0;
        padding-right: 0;
    }
    .lib-inner-title div{
        width: 100%;
        text-align: center;
        font-size: 5vw;
    }
    .lib-inner-title input{
        width: 89%;
        font-size: 4vw;
        float: left;
        padding: 8px 20px;
        padding-left: 15%;
    }
    .lib-filter-stories{
        display: block;
        width: 9%;
        float: left;
        margin-left: 1.5%;
        margin-top: 5px;
    }
    .lib-genres-slider{
        display: none;
    }
    .site-stories-wrap{
        overflow-x: hidden;
        padding: 0 1.5% 0 1.5%;
    }
    .home-stories-row:first-child{
        margin-top: 0 !important;
    }
    .home-stories-row h2,.library-results-title{
        font-size: 4.5vw;
        margin-bottom: 5px;
    }
    .library-results-title{
        margin-top: 0;
    }
    .library-results-title img{
        width: 6%;
    }
    .library-loading-wrap {
        position: fixed;
        width: 100%;
        left: 0;
        top: 47px;
    }
    .library-loading-mob{
        display: block;
    }
    .library-loading-web{
        display: none;
    }

    .lib-filter-overlay{
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background: #333333;
        opacity: .9;
    }
    .library-filter {
        position: fixed;
        z-index: 10000000;
        width: 96%;
        height: 96%;
        top: 2%;
        left: 2%;
        background: #fcf0e8;
        overflow-y: scroll;
        border-radius: 5px;
        display: inline-block;
    }
    .lib-category-item {
        background: #e38185;
        border-radius: 5px;
        width: 31%;
        margin-left: 1.5%;
        margin-bottom: 5px;
        float: left;
        padding-top: 30px;
        padding-bottom: 10px;
    }
    .lib-category-item img {
        display: block;
        margin: 20px auto;
        margin-top: 10px;
        width: 60%;
    }
    .lib-category-title {
        color: #ffffff;
        text-align: center;
        text-transform: uppercase;
        min-height: 50px;
        font-size: 3.4vw;
        width: 100%;
    }
    .close-library-filter{
        position: absolute;
        top:20px;
        right: 20px;
        width: 8%;
    }
    .close-library-filter img{
        width: 100%;
    }
    .lib-filter-title{
        margin-top: 25px;
        margin-bottom: 20px;
        font-size: 6vw;
        text-align: center;
    }
}

@media only screen and (max-width: 420px) {
    .mob-lib-results-loading{
        display: block;
    }
    .web-lib-results-loading{
        display: none;
    }
    .lib-title{
        font-size: 6vw;
    }
    .lib-inner-title div{
        font-size: 5vw;
    }
    .lib-inner-title input{
        font-size: 4vw;
    }
    .home-stories-row h2,.library-results-title{
        font-size: 4.5vw;
    }
    .site-stories-wrap .app-title {
        margin-top: 0px !important;
    }
}

@-webkit-keyframes blink {
    50% { opacity: 1; }
}

@keyframes blink {
    50% { opacity: 1; }
}

.single-story-wrap{
    padding: 0;
    background: #fffaf6;
    overflow-x: hidden;
}
.single-story-header{
    position:relative;
}
.single-story-header img{
    position:relative;
    width: 100%;
}
.single-mob-bg{
    display:none;
}
.single-story-header-overlay{
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: .4;
}
.single-story-header-wrap{
    width: 80%;
    top:40%;
    color:white;
}
.single-story-header-wrap h1{
    font-size: 2.2vw;
}
.single-story-by{
    font-size:1.6vw;
}
.single-story-by-small{
    font-size: .9vw;
    margin: 0 10px;
}
.single-story-date{
    margin-top:5px;
    font-size:1vw;
}

.single-story-body{
    width: 80%;
    margin: 0 auto;
    position: relative;
    top:-50px;
    min-height: 600px;
    background: #fdf0e8;
    border-radius: 16px;
}
.single-story-body h2{
    position: relative;
    top:-40px;
    background: #fb7569;
    border-radius: 16px;
    min-width:15%;
    max-width:50%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    padding:30px 50px;
    color:white;
    text-align: center;
}
.single-story-type{
    font-size: 1.2vw;
}
.single-story-genre{
    font-size: 1.4vw;
    margin-top: 5px;
}

.single-story-entries-wrap{
    padding: 40px;
    width: 75%;
    margin: 0 auto;
    font-size: 1.2vw;
    font-weight: 500;
    color: #000000;
    word-break: break-word;
}
.single-story-entry {
    margin-bottom: 35px;
}

.single-story-views{
    color: #bdaea7;
    font-size: 1.2vw;
    margin-bottom: 100px;
}

.story-not-found{
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.story-not-found h1{
    font-size: 2.5vw;
}
.story-not-found div{
    font-size: 2vw;
}

.snf-cont-col{
    position: relative;
}

.snf-inner{
    width: 100%;
}
.snf-inner .app-button{
    margin: 40px auto;
}

.snf-image-col{
    position: relative;
}
.snf-image-col img{
    width: 80%;
    display: block;
}


@media only screen and (max-width: 768px) {
    .single-mob-bg{
        display:block;
    }
    .single-web-bg{
        display:none;
    }

    .single-story-header-wrap h1{
        font-size: 5.2vw;
    }
    .single-story-by{
        font-size:4.6vw;
    }
    .single-story-by-small{
        font-size: 2.2vw;
    }
    .single-story-date{
        font-size:3.3vw;
    }

    .single-story-body h2{
        top:-20px;
        min-width:35%;
        max-width:70%;
        padding:20px 50px;
    }
    .single-story-type{
        font-size: 4.2vw;
    }
    .single-story-genre{
        font-size: 4.4vw;
    }

    .single-story-body{
        top:0;
        width: 100%;
        border-radius: 0;
    }

    .single-story-entries-wrap{
        width: 95%;
        font-size: 4.2vw;
    }

    .single-story-views{
        font-size: 4vw;
    }

    .story-not-found h1{
        font-size: 5.5vw;
    }
    .story-not-found div{
        font-size: 5vw;
    }
}

.reset-password-wrap{
    position: relative;
    padding: 0;
}
.reset-password-error-img{
    width: 100%;
}
.reset-password-error-img.mob{
    display: none;
}

.reset-password-wrap h2{
    font-size: 2.3vw;
    text-align: center;
    margin: 20px auto;
    color: #fb867c;
}
.reset-password-wrap p{
    margin: 30px auto;
    width: 70%;
    font-size: 1.2vw;
    color: #333;
    text-align: center;
}
.reset-password-wrap p a{
    color: #fb867c;
    text-decoration: underline;
}

@media only screen and (max-width: 768px) {
    .reset-password-error-img.mob{
        display: block;
    }
    .reset-password-error-img.web{
        display: none;
    }
    .reset-password-wrap h2{
        font-size: 6vw;
    }
    .reset-password-wrap p{
        font-size: 4vw;
        width: 80%;
    }
}
.privacy-policy-wrap{
    padding: 0px;
    overflow-x: hidden;
    padding-left: 5%;
    margin: 0 auto;
}
.privacy-policy-wrap .app-title{
    margin-top:70px !important;
}
.privacy-policy-inner-wrap{
    padding: 40px;
    padding-top: 0;
}
.privacy-policy-col{
    padding-left: 0 !important;
    padding-top:0;
}
.privacy-policy-wrap ul{
    list-style: none;
    width: 80%;
    margin: 0;
    padding: 0;
}
.privacy-policy-wrap ul li{
    margin-top: 25px;
    font-size: 1vw;
}
.privacy-policy-wrap h3{
    margin-top: 55px;
    font-size: 1.9vw;
    color: #fb7569;
}
.first-title{
    margin-top: 0px !important;
}

.privacy-policy-wrap .contact-links{
    margin: 0 auto !important;
    margin-top: 50px !important;
    width: 80% !important;
    text-align: center;
}

.privacy-policy-wrap .contact-links a{
    color:#fb867c;
    display: inline-block;
    margin: 0px 15px;
    margin-right: 15px;
    text-transform: uppercase;
}
.privacy-policy-wrap .col-12,.privacy-policy-wrap .col-lg-12{
    padding: 0 !important;
}

@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {
    .privacy-policy-wrap .app-title{
        font-size: 4vw;
        margin-top: 100px !important;
    }
    .privacy-policy-wrap h3{
        font-size: 3.2vw;
    }
    .privacy-policy-wrap ul li{
        font-size: 2.5vw;
    }
    .privacy-policy-wrap ul{
        width: 96%;
    }
    .last-update{
        font-size: 2.8vw;
    }
}

@media only screen and (max-width: 768px) {
    .privacy-policy-wrap{
        margin-top: 50px;
    }
    .privacy-policy-inner-wrap{
        padding: 40px 20px;
        padding-top: 0;
    }
    .privacy-policy-wrap ul{
        width: 100%;
    }
    .privacy-policy-wrap ul li{
        font-size: 3vw;
    }
    .privacy-policy-wrap h3{
        font-size: 3.9vw;
    }
    .privacy-policy-wrap h3:first-child{
        padding-top: 0;
        margin-top: 0;
    }
    .privacy-policy-wrap .contact-links a{
        font-size: 3vw;
        text-align: center;
        display: block;
        margin: 0 auto;
        margin-top: 35px;
    }
    .privacy-policy-wrap .app-title{
        text-transform: uppercase;
        font-size: 5vw;
        text-align: center;
        width: 90%;
        margin-top: 50px !important;
        margin-bottom: 30px !important;
        padding-left:0;
        padding-right:0;
    }
    .policy-padding-dummy{
        min-height: 100px;
        width: 100%;
    }
}

@media only screen and (max-width: 420px) {
    .privacy-policy-wrap ul li{
        font-size: 4vw;
    }
    .privacy-policy-wrap h3{
        font-size: 4.9vw;
    }
    .privacy-policy-wrap .contact-links a{
        font-size: 3.5vw;
    }
    .privacy-policy-wrap .app-title{
        font-size: 6vw;
        margin-top: 0px !important;
        margin-bottom: 10px !important;
    }
}

@media only screen and (max-width: 320px){
    .privacy-policy-wrap{
        padding-left: 1%;
    }
    .privacy-policy-wrap .app-title{
        padding: 20px 0;
    }
    .privacy-policy-wrap h3{
        margin-top: 15px;
    }
}
.header-row{
    padding: 10px 6%;
    font-size: 5vw;
    background: #fc7569;
    color: white;
    display: none !important;
    position: fixed;
    width: 100%;
    z-index: 100;
}
.logo-name{
    float: right;
}
.menu-icon{
    font-size: 6vw;
}

@media only screen and (max-width: 768px) {
    .header-row{
        display: flex !important;
    }
}
.terms-wrap{
    padding: 0px;
    overflow-x: hidden;
    padding-left: 5%;
    margin: 0 auto;
    font-size: 1vw;
}
.terms-wrap {
    font-size: 1vw;
}
.terms-wrap .app-title{
    margin-top:70px !important;
}
.terms-inner-wrap{
    padding: 40px;
    padding-top: 0;
    width:80%;
}
.terms-wrap ul{
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
}
.terms-wrap h1{
    display: block;
    width: 100%;
    margin-bottom: 15px;
}
.terms-wrap h3{
    display: block;
    width: 100%;
    margin-top: 55px;
    font-size: 1.9vw;
    color: #fb7569;
}
.terms-wrap a{
    color: #fb7569;
    text-decoration: underline;
}
.terms-wrap .strong{
    font-weight: bold;
}
.terms-wrap .underline{
    text-decoration: underline;
}

@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {
    .terms-wrap .app-title{
        font-size: 4vw;
        margin-top: 100px !important;
    }
    .terms-wrap h3{
        font-size: 3.2vw;
    }
    .terms-wrap{
        font-size: 2.5vw;
    }
    .terms-wrap ul,.terms-inner-wrap{
        width: 96%;
    }
    .last-update{
        font-size: 2.8vw;
    }
}

@media only screen and (max-width: 768px) {
    .terms-wrap{
        margin-top: 50px;
        padding-bottom: 100px;
    }
    .terms-inner-wrap{
        padding: 40px 20px;
        padding-top: 0;
        width:100%
    }
    .terms-wrap ul{
        width: 100%;
    }
    .terms-wrap{
        font-size: 3vw;
    }
    .terms-wrap h3{
        font-size: 3.9vw;
    }
    .terms-wrap .app-title{
        text-transform: uppercase;
        font-size: 5vw;
        text-align: center;
        width: 90%;
        margin-top: 50px !important;
        margin-bottom: 30px !important;
        padding-left:0;
        padding-right:0;
    }
}

@media only screen and (max-width: 420px) {
    .terms-wrap{
        font-size: 4vw;
    }
    .terms-wrap h3{
        font-size: 4.9vw;
    }
    .terms-wrap .app-title{
        font-size: 5vw;
        margin-top: 0px !important;
        margin-bottom: 10px !important;
    }
}

@media only screen and (max-width: 320px){
    .terms-wrap{
        padding-left: 1%;
    }
    .terms-wrap .app-title{
        padding: 20px 0;
    }
    .terms-wrap h3{
        margin-top: 15px;
    }
}


.custom-story-wrap{
    padding: 0;
    background: #fffaf6;
    overflow-x: hidden;
    position: relative;
}
.custom-story-header{
    position:relative;
}
.custom-story-header img{
    position:relative;
    width: 100%;
}
.custom-mob-bg{
    display:none;
}
.custom-story-header-overlay{
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: .3;
}
.custom-story-header-wrap{
    width: 80%;
    top:40%;
    color:white;
}
.custom-story-header-wrap h1{
    font-size: 2.2vw;
}
.custom-story-by{
    font-size:1.6vw;
}
.custom-story-by-small{
    font-size: .9vw;
    margin: 0 10px;
}
.custom-story-date{
    margin-top:5px;
    font-size:1vw;
}
.custom-story-lib-link{
    position: absolute;
    left: 40px;
    top:40px;
    font-size: 1vw;
    color:white;
    width: 50%;
    cursor: pointer;
}
.custom-story-lib-link img{
    width: 4%;
}
.custom-story-lib-link span{
    margin-left: 20px;
    left:11%;
    top:60%;
}
.custom-story-lib-link span a{
    color:white;
}

.custom-story-body{
    width: 96%;
    margin: 0 auto;
    position: relative;
    top:-50px;
    min-height: 600px;
    background: #fdf0e8;
    border-radius: 16px;
    padding-bottom: 30px;
}
.custom-story-genres{
    position: relative;
    top:-40px;
    background: #ed7c74;
    border-radius: 5px;
    max-width:70%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    padding:30px 50px;
    color:white;
    text-align: center;
}
.custom-story-type{
    font-size: 1.2vw;
}
.custom-story-genre{
    font-size: 1.2vw;
    margin-top: 5px;
}
.custom-story-maturity{
    font-size: 1vw;
}

.custom-story-entries-wrap{
    padding: 40px 0;
    width: 95%;
    margin: 0 auto;
    font-size: 1.2vw;
    font-weight: 500;
    color: #000000;
    position: relative;
}
.custom-story-page {
    padding: 0 30px;
    border: 0;
    outline: none;
    word-break: break-word;
}

.custom-story-views{
    color: #bdaea7;
    font-size: 1.5vw;
}

.story-not-found{
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.story-not-found h1{
    font-size: 2.5vw;
}
.story-not-found div{
    font-size: 2vw;
}

.snf-cont-col{
    position: relative;
}

.snf-inner{
    width: 100%;
}
.snf-inner .app-button{
    margin: 40px auto;
}

.snf-image-col{
    position: relative;
}
.snf-image-col img{
    width: 80%;
    display: block;
}

.custom-story-title-meta{
    width: 40%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px !important;
}
.custom-story-title-meta-item{
    display: inline-block;
    text-align: center;
    width: 18%;
}
.custom-story-title-meta-item img{
    width: 24%;
}
.custom-story-title-meta-item span{
    color: #ffffff;
    position: relative;
    top:-4px;
    font-size: .8vw;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
}
.custom-story-pagination{
    width: 90%;
    margin: 20px auto;
    margin-top: 40px;
}
.custom-story-pagination-inner{
    display: inline-block;
    width: 100%;
    text-align: center;
}
.custom-story-pagination-item{
    width: 35px;
    height: 35px;
    border-radius: 100px;
    margin-right: 5px;
    font-size: .9vw;
    display: inline-block;
    cursor: pointer;
    position: relative;
}
.custom-story-pagination-item.active{
    color:#fb7569;
}
.custom-story-pagination-item:hover{
    background: #fffaf6;
}
.custom-story-sep {
    color: gray;
    font-size: 3.5vw;
    border-bottom: 1px solid lightgray;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 95%;
    margin: 0 auto;
}
.custom-story-comments-wrap{
    padding: 40px 0;
    width: 95%;
    margin: 0 auto;
    font-size: 1.2vw;
    font-weight: 500;
    color: #000000;
}
.custom-story-comments-title{
    margin-left: 5%;
    font-size: 1.3vw;
    margin-bottom: 20px;
    color: gray;
}
.custom-story-comments-body{
    background: #fffaf6;
    border-radius: 5px;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 50px;
}
.load-more-comments,.load-more-replies{
    text-align: center;
    margin: 0 auto;
    padding: 30px 0;
    padding-top: 50px;
    color: gray;
    font-size: 1.2vw;
    cursor: pointer;
}
.load-more-comments:hover,.load-more-replies:hover{
    color: #333333;
}
.load-more-replies{
    font-size: .9vw;
    padding: 10px 0;
    padding-top: 30px;
}
.lib-banner{
    width: 86%;
    margin: 30px auto;
    border-radius: 5px;
    background: #fb7669;
    color: white;
    font-size: 2vw;
    position: relative;
}
.lib-banner a{
    display: block;
    width: 100%;
    color: white !important;
    text-decoration: none !important;
}
.lib-banner img{
    transform: translate(0, 0);
    left: 0;
    bottom: 0;
    width: 40%;
    position: relative;
    top: 0px;
}
.lib-banner div{
    width: 50%;
    text-align: center;
    left: 65%;
    color: white !important;
}
.story-pages-arrow{
    position: absolute;
    top:35%;
    width: 4%;
    left:-5%;
    cursor: pointer;
}
.story-pages-arrow-right{
    left: 100%;
}
.story-swipe-info{
    display: none;
}
.story-pop-overlay{
    position: fixed;
    background: #000000;
    opacity: .8;
    width: 100%;
    height: 100%;
    z-index: 1000001;
    left: 0;
    top: 0;
}

@media only screen and (max-width: 768px) {
    .custom-story-page {
        padding: 0 10px;
        font-size: 4vw;
        line-height: 20px;
    }
    .custom-mob-bg{
        display:block;
    }
    .custom-web-bg{
        display:none;
    }

    .custom-story-header-wrap{
        top:50%;
    }
    .custom-story-header-wrap h1{
        font-size: 5.2vw;
    }
    .custom-story-by{
        font-size:4.6vw;
    }
    .custom-story-by-small{
        font-size: 2.2vw;
    }
    .custom-story-date{
        font-size:3.3vw;
    }

    .custom-story-genres{
        top: -20px;
        max-width: 85%;
        width: 85%;
        padding: 10px;
    }
    .custom-story-type{
        font-size: 4.2vw;
    }
    .custom-story-genre{
        font-size: 3.8vw;
    }

    .custom-story-body {
        top: -30px;
        width: 94%;
        border-radius: 5px;
        line-height: 30px;
        padding-bottom: 1px;
    }

    .custom-story-entries-wrap{
        width: 95%;
        font-size: 4.2vw;
    }

    .custom-story-views{
        font-size: 4vw;
    }

    .story-not-found h1{
        font-size: 5.5vw;
    }
    .story-not-found div{
        font-size: 5vw;
    }
    .custom-story-lib-link{
        left:15px;
        top:15px;
        width: 80%;
        font-size: 3.7vw;
    }
    .custom-story-lib-link img{
        width: 7%;
    }
    .custom-story-lib-link span{
        left: 18%;
    }
    .custom-story-title-meta{
        width: 80%;
        margin-top: 0 !important;
    }
    .custom-story-title-meta-item{
        width: 28%;
    }
    .custom-story-title-meta-item img{
        width: 34%;
    }
    .custom-story-title-meta-item span{
        font-size: 2.8vw;
    }
    .custom-story-maturity{
        font-size: 2.7vw;
    }
    .story-pages-arrow{
        width: 8%;
    }
    .story-pages-arrow-left{
        left: -6%;
    }
    .story-pages-arrow-right{
        left: 98%;
    }
    .custom-story-pagination{
        display: none;
    }

    .story-swipe-info {
        position: relative;
        margin: 15px auto;
        font-size: 4vw;
        text-align: center;
        color: rgba(115,115,115,.7);
        display: block;
    }
    .story-swipe-info img{
        position: absolute;
        width: 10%;
        top:13%;
    }
    .sign-ra{
        left: 80%;
    }
    .sign-la{
        left: 20%;
    }
    .story-swipe-note{
        font-size: 3.5vw;
        margin-top: 20px;
    }
    .story-current-page{
        background: #ffffff;
        color:#fb7669;
        padding: 5px 8px;
        border-radius: 5px;
        margin-right: 5px;
        position: relative;
        top:-1px;
    }
    .story-total-pages{
        padding: 5px 8px;
        position: relative;
        top:-1px;
    }
    .choose-story-page{
        position: fixed;
        z-index: 100000000000;
        width: 80%;
        min-height: 30px;
        padding: 20px 0;
        background: #fdf0e8;
        border-radius: 5px;
        padding-top: 60px;
    }
    .choose-story-page img{
        top:20px;
        right:20px;
        width: 7%;
        position: absolute;
    }
    .choose-story-page input{
        background: #ffffff;
        text-align: center;
        width: 80%;
        padding: 10px;
        font-size: 4.5vw;
        display: block;
        margin: 0 auto;
        border: 0;
        outline: 0;
        border-radius: 100px;
        color: #fb7669;
    }
    .choose-story-page .app-button{
        display: block !important;
        margin: 0 auto !important;
        margin-top: 20px !important;
        font-size: 3.5vw !important;
        height: 35px !important;
        width: 50% !important;
        line-height: 35px;
        padding: 0;
    }
    .custom-story-entries-wrap{
        padding-bottom: 0;
    }
    .custom-story-comments-wrap{
        padding: 0;
    }
    .custom-story-comments-title{
        font-size: 4vw;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .custom-story-comments-body{
        display: none;
    }
    .lib-banner{
        margin-top: 0;
        width: 94%;
        font-size: 4vw;
        margin-bottom: 100px;
    }
    .lib-banner img{
        top:0;
    }
    .lib-banner div{
        width: 60%;
    }
    .story-comments-pop{
        background: #fffaf6;
        position: fixed;
        width: 100%;
        height: 100%;
        top:0;
        z-index: 10000;
    }
    .replies-pop{
        z-index: 100000;
    }
    .comments-body{
        position: absolute;
        overflow-y: scroll;
        height: 100%;
        top: 5%;
        z-index: 10000;
        padding-bottom: 100px;
    }
    .comments-title{
        color: #ed7c74;
        width: 90%;
        margin: 5px auto;
        padding-top: 10px;
    }
    .comments-title svg{
        width: 8%;
        margin-right: 10px;
        fill: #ed7c74;
    }
    .comments-title span{
        position: relative;
        top:3px;
    }
    .load-more-comments, .load-more-replies {
        padding: 10px 0;
        padding-top: 30px;
        font-size: 3.5vw;
    }
    .replies-inner{
        width: 90%;
        margin-left: 10%;
    }
    .replies-inner .story-comment{
        background: rgba(239, 208, 194, 0.7) !important;
    }
}
.story-comment{
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
    display: inline-block;
    background: #fcf0e8;
    padding: 10px;
    border-radius: 5px;
}
.replies-wrap .story-comment{
    background: rgba(239, 208, 194, .7);
    width: 100%;
    margin-left: 0%;
}
.story-comment-img{
    display: block;
    float: left;
    width: 4vw;
    height: 4vw;
    object-fit: cover;
    margin-right: 2%;
    border-radius: 100px;
}
.story-comment-body{
    float: left;
    width: 80%;
    font-size: 1.3vw;
}
.comment-date{
    margin-bottom: 10px;
    color:gray;
    font-size: .9vw;
    padding-top: 10px;
}
.comment-footer{
    margin-top: 5px;
    display: inline-block;
    width: 100%;
    text-align: center;
}
.comment-footer span{
    font-size: .3vw;
    margin-right: 10px;
    width: 100%;
    display: inline-block;
    color: gray;
}
.comment-footer.reply-footer span{
    font-size: .6vw;
}
.comment-footer span div{
    margin-top: 5px;
    text-align: center;
}
.comment-footer-report{
    display: block;
    float: right;
}
.comment-footer img{
    width: 50%;
    position: relative;
    top:3px;
}
.comment-footer ion-grid{
    padding-left: 0;
    padding-right: 0;
}
.comment-footer ion-grid ion-col:first-child{
    padding-left: 0;
}
.comment-footer ion-grid ion-col:last-child{
    padding-right: 0;
}
.comment-footer ion-grid ion-col:last-child img,.comment-footer ion-grid ion-col:first-child img{
    width: 42.5%;
}

.comment-likes-wrap{
    padding: 5px 8px;
    background: #ffffff;
    border-radius: 100px;
    margin-left: 10px;
    font-size: 1vw;
    color:#fb7669;
}
.comment-likes-wrap img{
    width: 16px;
    height: 16px;
    position: relative;
    top:0;
}
.comment-likes-show{
    opacity: 1 !important;
}
.story-comment-body-inner{
    word-break: break-word;
}
.replies-preview{
    width: 100%;
    color:gray;
    margin-top: 10px;
    font-size: 1vw;
    background: #ffffff;
    padding: 7px 10px;
    border-radius: 100px;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .replies-wrap{
        display: none;
    }
    .story-comment-img{
        width: 6vw;
        height: 6vw;
    }
    .story-comment{
        width: 90%;
        margin-left: 5%;
    }
    .comment-date{
        font-size: 3.5vw;
        padding-top: 0;
    }
    .story-comment-body{
        font-size: 4vw;
    }
    .replies-preview{
        font-size: 3.5vw;
    }
    .story-comment-body{
        width: 90%;
    }
}
.lib-genres-slider{
    margin: 20px auto;
    margin-top: 0;
}
.genre-item{
    display: inline-block;
    margin-right: 10px;
    font-size: .9vw;
    background: #efd0c2;
    color: #fa7669;
    padding: 8px 20px;
    border-radius: 100px;
    border: 3px solid #fffaf6;
    text-align: center;
    outline: 0 !important;
    cursor: pointer;
}
.genre-item:hover{
    opacity: .8;
}
.category-results-wrap{
    position: static !important;
}
.category-no-results .app-button{
    margin-top: 15px;
}

@media only screen and (max-width: 768px) {
    .category-title .lib-genres-slider{
        display: block;
    }
    .genre-item{
        font-size: 2.3vw;
    }
    .library-search-results{
        min-height: auto;
    }
}

