.contact-page-wrap{
    padding: 0;
    overflow-x: hidden;
}
.contact-page-wrap .app-title{
    padding-left: 10px !important;
    margin-top:70px !important;
}
.login{
    top: 50%;
    width: 96%;
    display: table;
    margin: 50px auto;
    margin-top:0;
    background: #fcf0e8;
    border-radius: 4px;
}

.inner-contact-form-wrap{
    width: 90%;
    margin: 0 auto !important;
}

fieldset{
    border: 0;
}

.contact-input {
    position: relative;
    width: 100%;
    margin: 15px auto;
}

.contact-input span{
    position: absolute;
    display: block;
    color: #baaaa4;
    left: 10px;
    top: 8px;
    font-size: 1.2vw;
    z-index: 10;
    width: 3.5%;
}
.contact-input span img{
    width: 100%;
}

.contact-input input,.contact-input textarea{
    width: 100%;
    padding: 10px 5px 10px 40px;
    display: block;
    border: 1px solid #fdf0e9;
    border-radius: 16px;
    transition: 0.2s ease-out;
    color: #fb867c;
    font-size: 1.2vw;
    background: #fdf0e9;
    outline: 0;
}

.contact-input input::-webkit-input-placeholder,.contact-input textarea::-webkit-input-placeholder {color: #baaaa4;}
.contact-input input::-moz-placeholder,.contact-input textarea::-moz-placeholder  {color: #baaaa4;}
.contact-input input:-ms-input-placeholder,.contact-input textarea:-ms-input-placeholder  { color: #baaaa4;}
.contact-input input:-moz-placeholder,.contact-input textarea:-moz-placeholder  {color: #baaaa4;}

.contact-input input:focus,.contact-input textarea:focus{
    padding: 10px 5px 10px 10px;
    border-color: #FF7052;
}

.contact-input:focus{
    padding: 10px 5px 10px 10px;
    outline: 0;
    border-color: #FF7052;
}
.contact-input:focus-within span{
    display: none;
}

.contact-input label{
    font-size: 3vw;
}

.about-me-counter{
    font-size: 2.5vw;
    float: right;
    margin-top:5px;
}

.contact-input textarea{
    resize: none;
    min-height: 400px;
}
.submit-contact{
    display: block;
    padding: 5px 5px;
    width: 30%;
    text-align: center;
    background: #fb867c;
    color: #fdf2ec;
    border-radius: 100px;
    box-shadow: none;
    font-size: 1.2vw;
    border: 0;
    outline: 0 !important;
    float: right;
    margin-top: 15px;
    margin-right: 0%;
}

#send-form-success{
    text-align: center;
    font-size: 5vw;
    position: absolute;
    top:50%;
    left:50%;
    width:80%;
    transform:translate(-50%,-50%);
}

.contact-image-col img{
    width: 80%;
    margin: 0 auto;
}

.contact-error{
    font-size:1.1vw;
    color:red;
    min-height: 25px;
}
.contact-error.success{
    color:green;
}
.grecaptcha-badge{
    bottom: 0 !important;
    right: unset !important;
    left:2% !important;
}

@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {
    .contact-image-col{
        display: none;
    }
    .contact-form-col{
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    .contact-page-wrap .app-title{
        font-size: 5vw;
        margin-top: 100px !important;
    }
    .contact-input input, .contact-input textarea{
        font-size: 3.6vw;
        padding: 10px 5px 10px 80px;
    }
    .contact-input span{
        width: 5.5%;
    }
    .submit-contact{
        font-size: 4vw;
    }
}

@media only screen and (max-width: 768px) {
    .contact-page-wrap{
        padding-bottom: 100px;
    }
    .contact-input input,.contact-input textarea{
        font-size: 4.2vw;
    }
    .contact-input textarea{
        min-height:300px;
    }
    .contact-input input, .contact-input textarea{
        padding: 10px 5px 10px 60px;
    }
    .contact-input span{
        font-size: 4.2vw;
        width: 5%;
    }
    .contact-input span img{
        left: 0;
    }
    .submit-contact{
        font-size: 4.2vw;
    }
    .inner-contact-form-wrap{
        position: static;
        transform: translate(0,0);
        margin: 0 auto !important;
    }
    .inner-contact-form-wrap img{
        position: relative;
        left:-17%;
        top:-15%;
        width: 75%;
    }
    .contact-page-wrap .app-title{
        text-transform: uppercase;
        font-size: 6vw;
        text-align: center;
        width: 80%;
        margin: 0px auto !important;
        margin-top: 10px !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 10px !important;
    }
    .contact-image-col{
        display: none;
    }
    .contact-error{
        font-size: 4vw;
    }
    .contact-image-col{
        display: none;
    }
    .contact-form-col{
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}

@media only screen and (max-width: 420px) {
    .contact-input input,.contact-input textarea{
        font-size: 4.2vw;
    }
    .contact-input span{
        font-size: 4.2vw;
        width: 8%;
    }
    .submit-contact{
        font-size: 4.2vw;
        margin-top: 0;
    }
    .contact-page-wrap .app-title{
        font-size: 6vw;
        margin-top: 0 !important;
        padding-top: 10px !important;
    }
    .contact-error{
        font-size: 4vw;
    }
    .contact-input textarea{
        min-height:180px;
    }
    .contact-input:last-child{
        margin-bottom: 0;
    }
}