.site-stories-wrap{
    padding: 0 5% 0 5%;
    margin: 0 auto;
}
.site-stories-wrap .app-title{
    padding-bottom: 0;
    padding-left: 10px;
}
.lib-title{
    width: 100%;
}
.lib-inner-title{
    display: inline-block;
    width: 100%;
}
.lib-inner-title div{
    display: block;
    float: left;
}
.lib-inner-title input{
    display: block;
    float: right;
    width: 30%;
    border-radius: 100px;
    font-size: 1.1vw;
    border: 0;
    outline: 0;
    padding: 10px 20px;
    background-color: #fcf0e8;
    color: #333333;
    padding-left: 4%;
    background-size: 6% !important;
    background-position: 3% 50% !important;
    background-repeat: no-repeat !important;
}
.lib-inner-title input::-webkit-input-placeholder {color: rgba(115,115,155,.7);}
.lib-inner-title input::-moz-placeholder {color: rgba(115,115,155,.7);}
.lib-inner-title input:-ms-input-placeholder {color: rgba(115,115,155,.7);}
.lib-inner-title input:-moz-placeholder {color: rgba(115,115,155,.7);}
.library-inner-body{
    position: relative;
}
.library-search-results{
    position: absolute;
    width: 100%;
    top:0;
    left: 0;
    min-height: 100%;
    background: #fffaf6;
}
.library-no-results {
    font-size: 1.5vw;
    margin-top: 300px;
}
.library-results-title{
    font-size: 1.4vw;
    margin-bottom: 15px;
    text-transform: uppercase;
    color:#fb7669;
    margin-left: 1%;
    margin-top: 50px;
}
.library-results-title img{
    width: 2%;
    margin-right: 1%;
    display: block;
    float: right;
    cursor: pointer;
}
.hide-story-sliders{
    display: none;
}
.lib-genres-title{
    font-size: .8vw;
    position: relative;
    top:10px;
}
.lib-mob-title-div{
    display: none !important;
}
.lib-filter-stories{
    display: none;
}
.library-loading-wrap{
    position: fixed;
    width: 83%;
    height: 100%;
    z-index: 101;
    left: 16.5%;
    top: 0;
    background: #fffaf6;
}
.library-loading-web,.library-loading-mob,.mob-lib-results-loading,.web-lib-results-loading{
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    -webkit-animation: blink 3s infinite;
    opacity: 0.5;
}
.results-loading-wrap{
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
    background: #fffaf6;
}
.mob-lib-results-loading,.web-lib-results-loading{
    position: static;
}
.web-lib-results-loading{
    width: 100%;
    position: relative;
    top:-20px;
}
.mob-lib-results-loading{
    top:30px;
    display: none;
}
.library-loading-mob{
    display: none;
}

@media only screen and (max-width: 768px) {
    .site-stories-wrap .app-title{
        margin-top: 30px !important;
    }
    .lib-mob-title-div{
        display: block !important;
        margin-top: 30px !important;
        margin-bottom: 20px !important;
    }
    .lib-web-title-div{
        display: none !important;
    }
    .lib-title{
        font-size: 6vw;
        padding-left: 0;
        padding-right: 0;
    }
    .lib-inner-title div{
        width: 100%;
        text-align: center;
        font-size: 5vw;
    }
    .lib-inner-title input{
        width: 89%;
        font-size: 4vw;
        float: left;
        padding: 8px 20px;
        padding-left: 15%;
    }
    .lib-filter-stories{
        display: block;
        width: 9%;
        float: left;
        margin-left: 1.5%;
        margin-top: 5px;
    }
    .lib-genres-slider{
        display: none;
    }
    .site-stories-wrap{
        overflow-x: hidden;
        padding: 0 1.5% 0 1.5%;
    }
    .home-stories-row:first-child{
        margin-top: 0 !important;
    }
    .home-stories-row h2,.library-results-title{
        font-size: 4.5vw;
        margin-bottom: 5px;
    }
    .library-results-title{
        margin-top: 0;
    }
    .library-results-title img{
        width: 6%;
    }
    .library-loading-wrap {
        position: fixed;
        width: 100%;
        left: 0;
        top: 47px;
    }
    .library-loading-mob{
        display: block;
    }
    .library-loading-web{
        display: none;
    }

    .lib-filter-overlay{
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background: #333333;
        opacity: .9;
    }
    .library-filter {
        position: fixed;
        z-index: 10000000;
        width: 96%;
        height: 96%;
        top: 2%;
        left: 2%;
        background: #fcf0e8;
        overflow-y: scroll;
        border-radius: 5px;
        display: inline-block;
    }
    .lib-category-item {
        background: #e38185;
        border-radius: 5px;
        width: 31%;
        margin-left: 1.5%;
        margin-bottom: 5px;
        float: left;
        padding-top: 30px;
        padding-bottom: 10px;
    }
    .lib-category-item img {
        display: block;
        margin: 20px auto;
        margin-top: 10px;
        width: 60%;
    }
    .lib-category-title {
        color: #ffffff;
        text-align: center;
        text-transform: uppercase;
        min-height: 50px;
        font-size: 3.4vw;
        width: 100%;
    }
    .close-library-filter{
        position: absolute;
        top:20px;
        right: 20px;
        width: 8%;
    }
    .close-library-filter img{
        width: 100%;
    }
    .lib-filter-title{
        margin-top: 25px;
        margin-bottom: 20px;
        font-size: 6vw;
        text-align: center;
    }
}

@media only screen and (max-width: 420px) {
    .mob-lib-results-loading{
        display: block;
    }
    .web-lib-results-loading{
        display: none;
    }
    .lib-title{
        font-size: 6vw;
    }
    .lib-inner-title div{
        font-size: 5vw;
    }
    .lib-inner-title input{
        font-size: 4vw;
    }
    .home-stories-row h2,.library-results-title{
        font-size: 4.5vw;
    }
    .site-stories-wrap .app-title {
        margin-top: 0px !important;
    }
}

@keyframes blink {
    50% { opacity: 1; }
}
