.custom-story-wrap{
    padding: 0;
    background: #fffaf6;
    overflow-x: hidden;
    position: relative;
}
.custom-story-header{
    position:relative;
}
.custom-story-header img{
    position:relative;
    width: 100%;
}
.custom-mob-bg{
    display:none;
}
.custom-story-header-overlay{
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: .3;
}
.custom-story-header-wrap{
    width: 80%;
    top:40%;
    color:white;
}
.custom-story-header-wrap h1{
    font-size: 2.2vw;
}
.custom-story-by{
    font-size:1.6vw;
}
.custom-story-by-small{
    font-size: .9vw;
    margin: 0 10px;
}
.custom-story-date{
    margin-top:5px;
    font-size:1vw;
}
.custom-story-lib-link{
    position: absolute;
    left: 40px;
    top:40px;
    font-size: 1vw;
    color:white;
    width: 50%;
    cursor: pointer;
}
.custom-story-lib-link img{
    width: 4%;
}
.custom-story-lib-link span{
    margin-left: 20px;
    left:11%;
    top:60%;
}
.custom-story-lib-link span a{
    color:white;
}

.custom-story-body{
    width: 96%;
    margin: 0 auto;
    position: relative;
    top:-50px;
    min-height: 600px;
    background: #fdf0e8;
    border-radius: 16px;
    padding-bottom: 30px;
}
.custom-story-genres{
    position: relative;
    top:-40px;
    background: #ed7c74;
    border-radius: 5px;
    max-width:70%;
    width: fit-content;
    margin: 0 auto;
    padding:30px 50px;
    color:white;
    text-align: center;
}
.custom-story-type{
    font-size: 1.2vw;
}
.custom-story-genre{
    font-size: 1.2vw;
    margin-top: 5px;
}
.custom-story-maturity{
    font-size: 1vw;
}

.custom-story-entries-wrap{
    padding: 40px 0;
    width: 95%;
    margin: 0 auto;
    font-size: 1.2vw;
    font-weight: 500;
    color: #000000;
    position: relative;
}
.custom-story-page {
    padding: 0 30px;
    border: 0;
    outline: none;
    word-break: break-word;
}

.custom-story-views{
    color: #bdaea7;
    font-size: 1.5vw;
}

.story-not-found{
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.story-not-found h1{
    font-size: 2.5vw;
}
.story-not-found div{
    font-size: 2vw;
}

.snf-cont-col{
    position: relative;
}

.snf-inner{
    width: 100%;
}
.snf-inner .app-button{
    margin: 40px auto;
}

.snf-image-col{
    position: relative;
}
.snf-image-col img{
    width: 80%;
    display: block;
}

.custom-story-title-meta{
    width: 40%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px !important;
}
.custom-story-title-meta-item{
    display: inline-block;
    text-align: center;
    width: 18%;
}
.custom-story-title-meta-item img{
    width: 24%;
}
.custom-story-title-meta-item span{
    color: #ffffff;
    position: relative;
    top:-4px;
    font-size: .8vw;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
}
.custom-story-pagination{
    width: 90%;
    margin: 20px auto;
    margin-top: 40px;
}
.custom-story-pagination-inner{
    display: inline-block;
    width: 100%;
    text-align: center;
}
.custom-story-pagination-item{
    width: 35px;
    height: 35px;
    border-radius: 100px;
    margin-right: 5px;
    font-size: .9vw;
    display: inline-block;
    cursor: pointer;
    position: relative;
}
.custom-story-pagination-item.active{
    color:#fb7569;
}
.custom-story-pagination-item:hover{
    background: #fffaf6;
}
.custom-story-sep {
    color: gray;
    font-size: 3.5vw;
    border-bottom: 1px solid lightgray;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 95%;
    margin: 0 auto;
}
.custom-story-comments-wrap{
    padding: 40px 0;
    width: 95%;
    margin: 0 auto;
    font-size: 1.2vw;
    font-weight: 500;
    color: #000000;
}
.custom-story-comments-title{
    margin-left: 5%;
    font-size: 1.3vw;
    margin-bottom: 20px;
    color: gray;
}
.custom-story-comments-body{
    background: #fffaf6;
    border-radius: 5px;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 50px;
}
.load-more-comments,.load-more-replies{
    text-align: center;
    margin: 0 auto;
    padding: 30px 0;
    padding-top: 50px;
    color: gray;
    font-size: 1.2vw;
    cursor: pointer;
}
.load-more-comments:hover,.load-more-replies:hover{
    color: #333333;
}
.load-more-replies{
    font-size: .9vw;
    padding: 10px 0;
    padding-top: 30px;
}
.lib-banner{
    width: 86%;
    margin: 30px auto;
    border-radius: 5px;
    background: #fb7669;
    color: white;
    font-size: 2vw;
    position: relative;
}
.lib-banner a{
    display: block;
    width: 100%;
    color: white !important;
    text-decoration: none !important;
}
.lib-banner img{
    transform: translate(0, 0);
    left: 0;
    bottom: 0;
    width: 40%;
    position: relative;
    top: 0px;
}
.lib-banner div{
    width: 50%;
    text-align: center;
    left: 65%;
    color: white !important;
}
.story-pages-arrow{
    position: absolute;
    top:35%;
    width: 4%;
    left:-5%;
    cursor: pointer;
}
.story-pages-arrow-right{
    left: 100%;
}
.story-swipe-info{
    display: none;
}
.story-pop-overlay{
    position: fixed;
    background: #000000;
    opacity: .8;
    width: 100%;
    height: 100%;
    z-index: 1000001;
    left: 0;
    top: 0;
}

@media only screen and (max-width: 768px) {
    .custom-story-page {
        padding: 0 10px;
        font-size: 4vw;
        line-height: 20px;
    }
    .custom-mob-bg{
        display:block;
    }
    .custom-web-bg{
        display:none;
    }

    .custom-story-header-wrap{
        top:50%;
    }
    .custom-story-header-wrap h1{
        font-size: 5.2vw;
    }
    .custom-story-by{
        font-size:4.6vw;
    }
    .custom-story-by-small{
        font-size: 2.2vw;
    }
    .custom-story-date{
        font-size:3.3vw;
    }

    .custom-story-genres{
        top: -20px;
        max-width: 85%;
        width: 85%;
        padding: 10px;
    }
    .custom-story-type{
        font-size: 4.2vw;
    }
    .custom-story-genre{
        font-size: 3.8vw;
    }

    .custom-story-body {
        top: -30px;
        width: 94%;
        border-radius: 5px;
        line-height: 30px;
        padding-bottom: 1px;
    }

    .custom-story-entries-wrap{
        width: 95%;
        font-size: 4.2vw;
    }

    .custom-story-views{
        font-size: 4vw;
    }

    .story-not-found h1{
        font-size: 5.5vw;
    }
    .story-not-found div{
        font-size: 5vw;
    }
    .custom-story-lib-link{
        left:15px;
        top:15px;
        width: 80%;
        font-size: 3.7vw;
    }
    .custom-story-lib-link img{
        width: 7%;
    }
    .custom-story-lib-link span{
        left: 18%;
    }
    .custom-story-title-meta{
        width: 80%;
        margin-top: 0 !important;
    }
    .custom-story-title-meta-item{
        width: 28%;
    }
    .custom-story-title-meta-item img{
        width: 34%;
    }
    .custom-story-title-meta-item span{
        font-size: 2.8vw;
    }
    .custom-story-maturity{
        font-size: 2.7vw;
    }
    .story-pages-arrow{
        width: 8%;
    }
    .story-pages-arrow-left{
        left: -6%;
    }
    .story-pages-arrow-right{
        left: 98%;
    }
    .custom-story-pagination{
        display: none;
    }

    .story-swipe-info {
        position: relative;
        margin: 15px auto;
        font-size: 4vw;
        text-align: center;
        color: rgba(115,115,115,.7);
        display: block;
    }
    .story-swipe-info img{
        position: absolute;
        width: 10%;
        top:13%;
    }
    .sign-ra{
        left: 80%;
    }
    .sign-la{
        left: 20%;
    }
    .story-swipe-note{
        font-size: 3.5vw;
        margin-top: 20px;
    }
    .story-current-page{
        background: #ffffff;
        color:#fb7669;
        padding: 5px 8px;
        border-radius: 5px;
        margin-right: 5px;
        position: relative;
        top:-1px;
    }
    .story-total-pages{
        padding: 5px 8px;
        position: relative;
        top:-1px;
    }
    .choose-story-page{
        position: fixed;
        z-index: 100000000000;
        width: 80%;
        min-height: 30px;
        padding: 20px 0;
        background: #fdf0e8;
        border-radius: 5px;
        padding-top: 60px;
    }
    .choose-story-page img{
        top:20px;
        right:20px;
        width: 7%;
        position: absolute;
    }
    .choose-story-page input{
        background: #ffffff;
        text-align: center;
        width: 80%;
        padding: 10px;
        font-size: 4.5vw;
        display: block;
        margin: 0 auto;
        border: 0;
        outline: 0;
        border-radius: 100px;
        color: #fb7669;
    }
    .choose-story-page .app-button{
        display: block !important;
        margin: 0 auto !important;
        margin-top: 20px !important;
        font-size: 3.5vw !important;
        height: 35px !important;
        width: 50% !important;
        line-height: 35px;
        padding: 0;
    }
    .custom-story-entries-wrap{
        padding-bottom: 0;
    }
    .custom-story-comments-wrap{
        padding: 0;
    }
    .custom-story-comments-title{
        font-size: 4vw;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .custom-story-comments-body{
        display: none;
    }
    .lib-banner{
        margin-top: 0;
        width: 94%;
        font-size: 4vw;
        margin-bottom: 100px;
    }
    .lib-banner img{
        top:0;
    }
    .lib-banner div{
        width: 60%;
    }
    .story-comments-pop{
        background: #fffaf6;
        position: fixed;
        width: 100%;
        height: 100%;
        top:0;
        z-index: 10000;
    }
    .replies-pop{
        z-index: 100000;
    }
    .comments-body{
        position: absolute;
        overflow-y: scroll;
        height: 100%;
        top: 5%;
        z-index: 10000;
        padding-bottom: 100px;
    }
    .comments-title{
        color: #ed7c74;
        width: 90%;
        margin: 5px auto;
        padding-top: 10px;
    }
    .comments-title svg{
        width: 8%;
        margin-right: 10px;
        fill: #ed7c74;
    }
    .comments-title span{
        position: relative;
        top:3px;
    }
    .load-more-comments, .load-more-replies {
        padding: 10px 0;
        padding-top: 30px;
        font-size: 3.5vw;
    }
    .replies-inner{
        width: 90%;
        margin-left: 10%;
    }
    .replies-inner .story-comment{
        background: rgba(239, 208, 194, 0.7) !important;
    }
}