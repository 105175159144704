.home-stories-row{
    margin-top: 50px !important;
}
.home-stories-row h2{
    font-size: 1.4vw;
    margin-bottom: 15px;
    text-transform: uppercase;
    color:#fb7669;
}
.story-see-all{
    float: right;
    font-size: 1vw;
    color:#fb7669;
}
.slick-dots{
    padding-top: 20px !important;
}
.slick-dots li{
    width: 1.3vw !important;
}
.slick-dots li button:before {
    font-size: .9vw !important;
    color:#fb7669 !important;
}
.slick-dots li.slick-active button:before{
    color:#ffce00 !important;
}

.stories-slider-row{
    position: relative;
}
.slider-arrow{
    width: 1.5%;
    cursor: pointer;
    top:-4%;
}
.slider-arrow-left{
    left:92%;
}
.slider-arrow-right{
    left:98%;
}
.story-sliders-col{
    padding: 0 !important;
}
.stories-slider-row .slick-slide .col-12{
    padding: 8px;
}

@media only screen and (max-width: 768px) {
    .story-see-all{
        font-size: 2vw;
    }
    .slick-dots li{
        width: 1.6vw !important;
    }
    .slick-dots li button:before {
        font-size: 2.3vw !important;
        color:#fb7669 !important;
    }
    .stories-slider-row .slick-slide .col-12{
        padding: 5px;
    }
    .home-stories-row .col-9{
        padding-left: 2px;
    }
    .slider-arrow{
        width: 2.5%;
        top:-6%;
    }
    .slider-arrow-left{
        left: 90%;
    }
}

@media only screen and (max-width: 420px) {
    .story-see-all{
        font-size: 2vw;
    }
    .slick-dots li{
        width: 1.6vw !important;
    }
    .slick-dots li button:before {
        font-size: 2.3vw !important;
    }
    .stories-slider-row .slick-slide .col-12{
        padding: 2px;
    }
}