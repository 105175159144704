.terms-wrap{
    padding: 0px;
    overflow-x: hidden;
    padding-left: 5%;
    margin: 0 auto;
    font-size: 1vw;
}
.terms-wrap {
    font-size: 1vw;
}
.terms-wrap .app-title{
    margin-top:70px !important;
}
.terms-inner-wrap{
    padding: 40px;
    padding-top: 0;
    width:80%;
}
.terms-wrap ul{
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
}
.terms-wrap h1{
    display: block;
    width: 100%;
    margin-bottom: 15px;
}
.terms-wrap h3{
    display: block;
    width: 100%;
    margin-top: 55px;
    font-size: 1.9vw;
    color: #fb7569;
}
.terms-wrap a{
    color: #fb7569;
    text-decoration: underline;
}
.terms-wrap .strong{
    font-weight: bold;
}
.terms-wrap .underline{
    text-decoration: underline;
}

@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {
    .terms-wrap .app-title{
        font-size: 4vw;
        margin-top: 100px !important;
    }
    .terms-wrap h3{
        font-size: 3.2vw;
    }
    .terms-wrap{
        font-size: 2.5vw;
    }
    .terms-wrap ul,.terms-inner-wrap{
        width: 96%;
    }
    .last-update{
        font-size: 2.8vw;
    }
}

@media only screen and (max-width: 768px) {
    .terms-wrap{
        margin-top: 50px;
        padding-bottom: 100px;
    }
    .terms-inner-wrap{
        padding: 40px 20px;
        padding-top: 0;
        width:100%
    }
    .terms-wrap ul{
        width: 100%;
    }
    .terms-wrap{
        font-size: 3vw;
    }
    .terms-wrap h3{
        font-size: 3.9vw;
    }
    .terms-wrap .app-title{
        text-transform: uppercase;
        font-size: 5vw;
        text-align: center;
        width: 90%;
        margin-top: 50px !important;
        margin-bottom: 30px !important;
        padding-left:0;
        padding-right:0;
    }
}

@media only screen and (max-width: 420px) {
    .terms-wrap{
        font-size: 4vw;
    }
    .terms-wrap h3{
        font-size: 4.9vw;
    }
    .terms-wrap .app-title{
        font-size: 5vw;
        margin-top: 0px !important;
        margin-bottom: 10px !important;
    }
}

@media only screen and (max-width: 320px){
    .terms-wrap{
        padding-left: 1%;
    }
    .terms-wrap .app-title{
        padding: 20px 0;
    }
    .terms-wrap h3{
        margin-top: 15px;
    }
}

