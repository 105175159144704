.about-wrap{
    overflow-x: hidden;
    padding: 0 0 0 0;
    margin: 0 auto;
    padding-bottom: 100px;
}
.about-wrap a{
    color:#fb867c;
}
.about-wrap a:hover{
    color:#fb867c;
}
.about-wrap .app-title{
    padding-left: 0 !important;
    margin-top:0 !important;
    padding-top: 0 !important;
    margin-bottom: 50px;
    color:#fb7569;
    width: 100%;
}
.about-wrap h3{
    font-size: 1.9vw;
    color: #fb7569;
}
.about-section{
    padding: 0 0 0 5%;
    padding-top: 80px;
    font-size: 1.2vw;
    position: relative;
    overflow: visible;
}
.about-section-bg-1{
    left:30%;
    top:50%;
    width: 60%;
}
.about-section-bg-2{
    left:70%;
    top:50%;
    width: 100% !important;
}
.about-section-bg-3{
    left:30%;
    top:50%;
    width: 100% !important;
}

.about-infographic{
    width: 80%;
    margin: 0 auto;
    margin-bottom: 50px;
}
.about-infographic img{
    width: 100%;
}

.about-cont-1{
    padding-right: 17%;
}

.about-vid{
    position: relative;
    width: 82%;
    margin: 0 auto;
}
.about-slider{
    width: 62%;
    margin: 0 auto;
    position: relative;
}
.about-vid-pc{
    width: 100%;
}
.about-vid-play{
    top:45%;
    width: 25%;
    cursor: pointer;
    -webkit-transition: all ease-out .5s;
    -moz-transition: all ease-out .5s;
    -o-transition: all ease-out .5s;
    transition: all ease-out .5s;
    transform-origin: 0 0;
}
.about-vid-play:hover{
    width: 30%;
    opacity: .8;
}

.about-wrap .app-title.about-title-2{
    margin-top: 50px !important;
}

.about-wrap .app-title.about-story-modes-title{
    text-align: center;
    margin: 50px auto !important;
    margin-top: 150px !important;
}
.about-story-section img{
    display: block;
    margin: 0 auto;
    width: 60%;
}
.about-story-section{
    margin-bottom: 80px !important;
}
.about-story-section ul{
    list-style: none;
    margin-top: 30px;
    padding-left: 0;
    width: 90%;
}
.about-story-section ul li{
    margin-bottom: 30px;
}
.about-story-section-cont{
    width: 70%;
    text-align: left !important;
}

.creators-section{
    margin-top: 250px !important;
}
.creators-section h2{
    position: relative;
    top:150px;
    left:150px;
}
.creators-section img{
    width: 40%;
    margin: 0 auto;
    display: block;
}
.creators-section p{
    width: 80%;
    margin: 0 auto;
}

.about-slider-arrow{
    width: 4%;
    cursor: pointer;
}
.asa-right{
    left:104%;
}
.asa-left{
    left: -4%;
}
.about-infographic-text{
    position: absolute;
    width: 16%;
    text-align: center;
    font-size: 1vw;
}
.ait-1{
    left:10%;
    top:45%;
}
.ait-2{
    left:38%;
    top:45%;
}
.ait-3{
    left:68%;
    top:45%;
}
.ait-4{
    left:25%;
    top:83%;
}
.ait-5{
    left:55%;
    top:83%;
}
.about-infographic-mob{
    display: none;
}

@media only screen and (max-width: 1500px) {
    .creators-section h2 {
        top: 100px;
        left: 100px;
    }
    .creators-section{
        margin-top: 150px !important;
    }
    .about-story-section{
        margin-bottom: 60px !important;
    }
    .about-wrap .app-title.about-story-modes-title{
        margin-top: 100px !important;
    }
}

@media only screen and (max-width: 1366px) {
    .about-wrap .app-title.about-story-modes-title{
        margin-top: 70px !important;
        margin-bottom: 20px !important;
    }
}

@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {
    .about-wrap{
        padding-top: 100px !important;
    }
    .about-wrap .app-title{
        font-size: 4vw;
    }
    .about-section{
        font-size: 2vw;
    }
    .about-cont-1{
        padding-right: 0;
    }
    .about-vid{
        width: 100%;
    }
    .about-slider{
        width: 90%;
        margin-top: 50px;
    }
    .about-infographic{
        width: 90%;
    }
    .about-wrap .app-title.about-story-modes-title{
        margin-top: 100px !important;
    }
    .about-wrap h3{
        font-size: 3.2vw;
    }
    .about-story-section-cont{
        width: 90%;
    }
    .about-story-section img{
        width: 80%;
    }
    .creators-section img{
        width: 60%;
    }
    .creators-section h2 {
        top: 100px;
        left: 0px;
    }
    .creators-section p{
        width: 90%;
    }
    .about-infographic-text {
        width: 25%;
        font-size: 2vw;
    }
    .ait-1 {
        left: 1%;
        top: 47%;
    }
    .ait-2 {
        left: 33%;
        top: 47%;
    }
    .ait-3 {
        left: 66%;
        top: 47%;
    }
    .ait-4 {
        left: 20%;
        top: 83%;
    }
    .ait-5 {
        left: 52%;
        top: 83%;
    }
}

@media only screen and (max-width: 768px) {
    .about-wrap{
        padding-top: 100px !important;
    }
    .about-wrap .app-title{
        font-size: 4vw;
    }
    .about-section{
        font-size: 2vw;
    }
    .about-cont-1{
        padding-right: 0;
    }
    .about-vid{
        width: 100%;
    }
    .about-slider{
        width: 90%;
        margin-top: 50px;
    }
    .about-infographic{
        width: 90%;
    }
    .about-wrap .app-title.about-story-modes-title{
        margin-top: 100px !important;
    }
    .about-wrap h3{
        font-size: 3.2vw;
    }
    .about-story-section-cont{
        width: 90%;
    }
    .about-story-section img{
        width: 80%;
    }
    .creators-section img{
        width: 60%;
    }
    .creators-section h2 {
        top: 100px;
        left: 0px;
    }
    .creators-section p{
        width: 90%;
    }
    .about-infographic-text {
        width: 25%;
        font-size: 2vw;
    }
    .ait-1 {
        left: 1%;
        top: 47%;
    }
    .ait-2 {
        left: 33%;
        top: 47%;
    }
    .ait-3 {
        left: 66%;
        top: 47%;
    }
    .ait-4 {
        left: 20%;
        top: 83%;
    }
    .ait-5 {
        left: 52%;
        top: 83%;
    }
}

@media only screen and (max-width: 540px) {
    .about-wrap{
        padding-top: 130px !important;
    }
    .about-wrap .app-title{
        font-size: 5.5vw;
        text-align: center;
        padding-bottom: 30px;
        padding-right: 0 !important;
    }
    .about-section{
        font-size: 4vw;
        padding-top: 0;
    }
    .about-cont-1{
        padding-right: 0;
        margin-bottom: 50px;
    }
    .about-vid{
        width: 100%;
    }
    .about-slider{
        width: 90%;
        margin-top: 50px;
    }
    .about-infographic{
        width: 100%;
    }
    .about-wrap .app-title.about-story-modes-title{
        margin-top: 40px !important;
        margin-bottom: 20px !important;
        padding-bottom: 0 !important;
    }
    .about-wrap h3{
        font-size: 5vw;
        text-align: center;
    }
    .about-story-section ul{
        margin: 0 auto;
        margin-top: 30px;
    }
    .about-story-section-cont{
        width: 100%;
        padding-top: 40px;
        position: static !important;
        transform: translate(0,0);
    }
    .about-story-section{
        margin-bottom: 0px !important;
    }
    .about-story-section img{
        width: 80%;
    }
    .creators-section img{
        width: 60%;
    }
    .creators-section h2 {
        top: 0;
        left: 0;
    }
    .creators-section p{
        width: 90%;
    }
    .creators-section img{
        width: 90%;
    }
    .creators-section p{
        width: 100%;
        margin-top: 20px;
    }
    .creators-section{
        margin-top: 50px !important;
    }
    .about-section{
        padding-left: 0 !important;
    }
    .about-infographic-web{
        display: none;
    }
    .about-infographic-mob{
        display: block;
    }
    .about-infographic-text {
        width: 43%;
        font-size: 3.5vw;
    }
    .ait-1{
        top: 32%;
        left: 5%;
    }
    .ait-2{
        top: 32%;
        left: 50%;
    }
    .ait-3{
        top: 57%;
        left: 30%;
    }
    .ait-4{
        top: 85%;
        left: 5%;
    }
    .ait-5{
        top: 85%;
        left: 50%;
    }
}

@media only screen and (max-width: 420px) {
    .about-wrap{
        padding-top: 100px !important;
    }
}

@media only screen and (max-width: 280px) {
    .about-wrap .app-title.about-story-modes-title{
        margin-top: 0 !important;
    }
    .about-wrap{
        padding-top: 80px !important;
    }
}

