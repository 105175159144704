.single-story-wrap{
    padding: 0;
    background: #fffaf6;
    overflow-x: hidden;
}
.single-story-header{
    position:relative;
}
.single-story-header img{
    position:relative;
    width: 100%;
}
.single-mob-bg{
    display:none;
}
.single-story-header-overlay{
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: .4;
}
.single-story-header-wrap{
    width: 80%;
    top:40%;
    color:white;
}
.single-story-header-wrap h1{
    font-size: 2.2vw;
}
.single-story-by{
    font-size:1.6vw;
}
.single-story-by-small{
    font-size: .9vw;
    margin: 0 10px;
}
.single-story-date{
    margin-top:5px;
    font-size:1vw;
}

.single-story-body{
    width: 80%;
    margin: 0 auto;
    position: relative;
    top:-50px;
    min-height: 600px;
    background: #fdf0e8;
    border-radius: 16px;
}
.single-story-body h2{
    position: relative;
    top:-40px;
    background: #fb7569;
    border-radius: 16px;
    min-width:15%;
    max-width:50%;
    width: fit-content;
    margin: 0 auto;
    padding:30px 50px;
    color:white;
    text-align: center;
}
.single-story-type{
    font-size: 1.2vw;
}
.single-story-genre{
    font-size: 1.4vw;
    margin-top: 5px;
}

.single-story-entries-wrap{
    padding: 40px;
    width: 75%;
    margin: 0 auto;
    font-size: 1.2vw;
    font-weight: 500;
    color: #000000;
    word-break: break-word;
}
.single-story-entry {
    margin-bottom: 35px;
}

.single-story-views{
    color: #bdaea7;
    font-size: 1.2vw;
    margin-bottom: 100px;
}

.story-not-found{
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.story-not-found h1{
    font-size: 2.5vw;
}
.story-not-found div{
    font-size: 2vw;
}

.snf-cont-col{
    position: relative;
}

.snf-inner{
    width: 100%;
}
.snf-inner .app-button{
    margin: 40px auto;
}

.snf-image-col{
    position: relative;
}
.snf-image-col img{
    width: 80%;
    display: block;
}


@media only screen and (max-width: 768px) {
    .single-mob-bg{
        display:block;
    }
    .single-web-bg{
        display:none;
    }

    .single-story-header-wrap h1{
        font-size: 5.2vw;
    }
    .single-story-by{
        font-size:4.6vw;
    }
    .single-story-by-small{
        font-size: 2.2vw;
    }
    .single-story-date{
        font-size:3.3vw;
    }

    .single-story-body h2{
        top:-20px;
        min-width:35%;
        max-width:70%;
        padding:20px 50px;
    }
    .single-story-type{
        font-size: 4.2vw;
    }
    .single-story-genre{
        font-size: 4.4vw;
    }

    .single-story-body{
        top:0;
        width: 100%;
        border-radius: 0;
    }

    .single-story-entries-wrap{
        width: 95%;
        font-size: 4.2vw;
    }

    .single-story-views{
        font-size: 4vw;
    }

    .story-not-found h1{
        font-size: 5.5vw;
    }
    .story-not-found div{
        font-size: 5vw;
    }
}
