.story-comment{
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
    display: inline-block;
    background: #fcf0e8;
    padding: 10px;
    border-radius: 5px;
}
.replies-wrap .story-comment{
    background: rgba(239, 208, 194, .7);
    width: 100%;
    margin-left: 0%;
}
.story-comment-img{
    display: block;
    float: left;
    width: 4vw;
    height: 4vw;
    object-fit: cover;
    margin-right: 2%;
    border-radius: 100px;
}
.story-comment-body{
    float: left;
    width: 80%;
    font-size: 1.3vw;
}
.comment-date{
    margin-bottom: 10px;
    color:gray;
    font-size: .9vw;
    padding-top: 10px;
}
.comment-footer{
    margin-top: 5px;
    display: inline-block;
    width: 100%;
    text-align: center;
}
.comment-footer span{
    font-size: .3vw;
    margin-right: 10px;
    width: 100%;
    display: inline-block;
    color: gray;
}
.comment-footer.reply-footer span{
    font-size: .6vw;
}
.comment-footer span div{
    margin-top: 5px;
    text-align: center;
}
.comment-footer-report{
    display: block;
    float: right;
}
.comment-footer img{
    width: 50%;
    position: relative;
    top:3px;
}
.comment-footer ion-grid{
    padding-left: 0;
    padding-right: 0;
}
.comment-footer ion-grid ion-col:first-child{
    padding-left: 0;
}
.comment-footer ion-grid ion-col:last-child{
    padding-right: 0;
}
.comment-footer ion-grid ion-col:last-child img,.comment-footer ion-grid ion-col:first-child img{
    width: 42.5%;
}

.comment-likes-wrap{
    padding: 5px 8px;
    background: #ffffff;
    border-radius: 100px;
    margin-left: 10px;
    font-size: 1vw;
    color:#fb7669;
}
.comment-likes-wrap img{
    width: 16px;
    height: 16px;
    position: relative;
    top:0;
}
.comment-likes-show{
    opacity: 1 !important;
}
.story-comment-body-inner{
    word-break: break-word;
}
.replies-preview{
    width: 100%;
    color:gray;
    margin-top: 10px;
    font-size: 1vw;
    background: #ffffff;
    padding: 7px 10px;
    border-radius: 100px;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .replies-wrap{
        display: none;
    }
    .story-comment-img{
        width: 6vw;
        height: 6vw;
    }
    .story-comment{
        width: 90%;
        margin-left: 5%;
    }
    .comment-date{
        font-size: 3.5vw;
        padding-top: 0;
    }
    .story-comment-body{
        font-size: 4vw;
    }
    .replies-preview{
        font-size: 3.5vw;
    }
    .story-comment-body{
        width: 90%;
    }
}