.app-sidebar-col{
    padding: 0 !important;
}
.main-sidebar-col{
    padding: 0 !important;
}
.main-sidebar{
    background:#fb7569;
    color:white;
    height: 100vh;
    position: relative;
}
.main-sidebar h1 img{
    width: 90%;
    display: block;
    margin:0 auto;
}
.main-sidebar h1{
    text-align: center;
    font-size: 2vw;
    margin: 20px 0;
    margin-top: 30px;
    margin-bottom: 2px;
    display: block;
    text-align: center;
}
.side-icon-col{
    color:#fdd35b;
    font-size: 2vw;
    position: relative;
}
.side-icon-col svg{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}
.menu-row{
    padding: 10px;
    color:white;
    cursor: pointer;
    min-height: 50px;
}
.menu-row:hover,.menu-row.active{
    background: white;
    color: black;
}
.logo-menu-row.active{
    background: transparent;
}
.logo-menu-row:hover{
    background: transparent;
}
.menu-row:hover a{
    color: black;
}
.menu-row span{
    position: absolute;
    top:50%;
    transform: translate(0,-50%);
    left:5%;
    font-size: .9vw;
}
.menu-row a{
    color:white;
}
.menu-row a:hover{
    text-decoration: none;
}
.side-link-col{
    position: relative;
}

.site-subtitle{
    font-size: .9vw;
    text-align: center;
    margin-bottom: 35px;
}
.site-subtitle span{
    color: #fdd35b;
}

.close-app-sidebar{
    display: none;
}
.menu-row .col-2{
    padding: 0;
}
.menu-row img{
    width: 90%;
    position: relative;
    top:-5px;
}


@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {
    .app-sidebar-col.col-md-3{
        max-width: 0;
        flex:0 0 0%
    }
    .site-body.col-md-9{
        max-width: 100%;
        flex:0 0 100%
    }
    .main-sidebar{
        position: fixed !important;
        left: 0;
        top: 0;
        transform: translate3d(-100vw, 0, 0);
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        margin: 0 !important;
    }
    .main-sidebar.active{
        -webkit-animation: slide-in-left .5s forwards;
    }

    .main-sidebar.hidden{
        -webkit-animation: slide-out-left .5s forwards;
    }

    .close-app-sidebar{
        display: block;
        position: absolute;
        top:3%;
        right:5%;
        font-size: 6vw;
        font-weight: 500;
    }
    .header-row.row {
        display: flex !important;
    }
    .menu-row span{
        font-size: 5vw;
    }
    .main-sidebar h1{
        font-size: 9vw;
    }
    .main-sidebar h1 img{
        width: 50%;
    }
    .menu-row{
        padding: 48px 10px;
        min-height:65px;
    }
    .side-icon-col{
        font-size: 7vw;
    }

    .site-subtitle{
        font-size: 4vw;
    }
    .menu-row img{
        width: 50%;
    }
    .site-subtitle{
        display: none;
    }
    .menu-row{
        padding: 10px 10px;
    }
    .main-sidebar h1 img{
        width: 40%;
    }
    .menu-row span{
        left: 2%;
    }
}

@media only screen and (max-width: 768px) {
    .app-sidebar-col.col-md-3{
        max-width: 0;
        flex:0 0 0%
    }
    .site-body.col-md-9{
        max-width: 100%;
        flex:0 0 100%
    }
    .main-sidebar{
        position: fixed !important;
        left: 0;
        top: 0;
        transform: translate3d(-100vw, 0, 0);
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        margin: 0 !important;
    }


    .main-sidebar h1{
        font-size: 9vw;
    }
    .main-sidebar h1 img{
        width: 50%;
    }

    .menu-row{
        padding: 48px 10px;
        min-height:65px;
    }

    .menu-row span{
        font-size: 4vw;
        left:10%;
    }

    .side-icon-col{
        font-size: 7vw;
    }

    .site-subtitle{
        font-size: 4vw;
    }

    .main-sidebar.active{
        -webkit-animation: slide-in-left .5s forwards;
    }

    .main-sidebar.hidden{
        -webkit-animation: slide-out-left .5s forwards;
    }

    .close-app-sidebar{
        display: block;
        position: absolute;
        top:3%;
        right:5%;
        font-size: 6vw;
        font-weight: 500;
    }
    .menu-row img{
        width: 50%;
    }
    .site-subtitle{
        display: none;
    }
    .menu-row{
        padding: 10px 10px;
    }
    .main-sidebar h1 img{
        width: 40%;
    }
    .menu-row span{
        left: 2%;
    }
}

@media only screen and (max-width: 420px) {
    .main-sidebar h1{
        font-size: 9vw;
    }
    .menu-row span{
        font-size: 5vw;
    }
    .side-icon-col{
        font-size: 9vw;
    }
    .site-subtitle{
        font-size: 4vw;
    }
    .close-app-sidebar{
        font-size: 6vw;
    }
    .menu-row{
        padding: 15px 10px;
    }
    .menu-row img{
        width: 80%;
    }
}

@media only screen and (max-width: 320px){
    .menu-row {
        padding: 15px 10px;
    }
}

@keyframes slide-in-left {
    from {
        transform: translate3d(-100vw, 0, 0);
    }
    to {
        transform: translate3d(0vw, 0, 0);
    }
}

@keyframes slide-out-left {
    from {
        transform: translate3d(0vw, 0, 0);
    }
    to {
        transform: translate3d(-100vw, 0, 0);
    }
}