.download-app-banner-wrap{
    position: absolute;
    width: 100%;
    bottom: -20%;
    left:0%;
    background: #fdd35b;
    -webkit-animation: slide-in-bottom .5s forwards;
    animation-delay: .5s;
}

@media only screen and (max-width: 768px) {

}

@keyframes slide-in-bottom {
    from {
        bottom: -20%;
    }
    to {
        bottom: 0%;
    }
}

@keyframes slide-in-bottom-mob {
    from {
        bottom: -25%;
    }
    to {
        bottom: 0%;
    }
}