@import url('https://fonts.googleapis.com/css?family=Comfortaa');

html,body{
    overflow: hidden !important;
}
*{
    font-family:Comfortaa;
}
.row{
    margin: 0 !important;
}
.site-body{
    background: #fffaf6;
    padding: 0 !important;
}
.site-storeis-wrap{
    padding: 50px 6%;
}
.center{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    text-align: center;
}
.App{
    position: relative;
}
.app-page{
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    padding: 40px;
}
.app-page::-webkit-scrollbar {
    width: 10px;
    cursor:pointer;
}
.app-page::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
.app-page::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #fb7569;
}
.app-page::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255,0,0,0.4);
}

.app-title{
    text-transform: uppercase;
    font-size: 2.3vw;
    text-align: left;
    width: 80%;
    padding: 40px;
    padding-left: 0;
    color: #000;
    margin: 0 !important;
}

.loading-overlay{
    position:fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    display: none;
    background: rgba(0,0,0,.1);
}
.loading-overlay.show{
    display: block;
}
.loading-overlay img{
    width: 4%;
    background: transparent;
}
.loading-overlay.dark{
    background: rgba(0,0,0,.5);
}

.app-button{
    color:white;
    background: #fb867c;
    border-radius: 100px;
    text-align: center;
    padding: 10px 30px;
    font-size: 1vw;
    display: block;
    width: fit-content;
}
.app-button:hover{
    color: white;
    text-decoration: none;
}

.slick-slider{
    width: 100%;
}
.slick-slide {
    outline: none;
}

.screen-test-div {
    position: absolute;
    background: transparent;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -99999999999999;
    color: transparent;
}
.library-alpha-notice{
    position: absolute;
    background: darkred;
    opacity: .7;
    color: #ffffff;
    font-size: .7vw;
    padding: 10px 0;
    text-align: center;
    top:0;
    left: 0;
    width: 100%;
    z-index: 100;
}
.lib-page-wrap{
    padding-top: 20px;
}
.hide-library-alpha-notice{
    color: darkred;
    background: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 100px;
    font-size: .8vw;
    left:85%;
    cursor: pointer;
}
.hide-library-alpha-notice span{
    width: 90%;
}
.site-stories-wrap{
    padding-top: 30px !important;
}
.lib-page-wrap{
    padding-top: 40px !important;
}

@media only screen and (max-width: 768px) {
    .app-title{
        margin: 0 auto !important;
    }
    .app-page{
        margin-top: 47px;
    }
    .app-button{
        font-size: 3vw;
    }
    .library-alpha-notice{
        top:47px;
        font-size: 2.5vw;
        padding: 10px 5%;
        padding-right: 15%;
        text-align: left;
    }
    .hide-library-alpha-notice{
        width: 25px;
        height: 25px;
        font-size: 3.6vw;
        left: 93%;
    }
    .hide-library-alpha-notice span{
        top: 45%;
        width: 80%;
    }
    .site-stories-wrap{
        padding-top: 40px !important;
    }
    .lib-page-wrap{
        padding-top: 80px !important;
    }

    .app-page::-webkit-scrollbar {
        width: 0;
        cursor:unset;
    }
    .app-page::-webkit-scrollbar-track {
        -webkit-box-shadow: unset;
        -webkit-border-radius: unset;
        border-radius: unset;
    }
    .app-page::-webkit-scrollbar-thumb {
        -webkit-border-radius: unset;
        border-radius: unset;
        background: unset;
    }
    .app-page::-webkit-scrollbar-thumb:window-inactive {
        background: unset;
    }
}

