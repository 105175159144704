.reset-password-wrap{
    position: relative;
    padding: 0;
}
.reset-password-error-img{
    width: 100%;
}
.reset-password-error-img.mob{
    display: none;
}

.reset-password-wrap h2{
    font-size: 2.3vw;
    text-align: center;
    margin: 20px auto;
    color: #fb867c;
}
.reset-password-wrap p{
    margin: 30px auto;
    width: 70%;
    font-size: 1.2vw;
    color: #333;
    text-align: center;
}
.reset-password-wrap p a{
    color: #fb867c;
    text-decoration: underline;
}

@media only screen and (max-width: 768px) {
    .reset-password-error-img.mob{
        display: block;
    }
    .reset-password-error-img.web{
        display: none;
    }
    .reset-password-wrap h2{
        font-size: 6vw;
    }
    .reset-password-wrap p{
        font-size: 4vw;
        width: 80%;
    }
}