.story-preview-wrap{
    -webkit-border-radius:5px;
    -moz-border-radius:5px;
    border-radius:5px;
    background: #fdf0e8;
    padding: 15px;
    margin-bottom: 50px;
    position: relative;
}
.story-title,.story-preview-results-title{
    font-size: 1.1vw;
    margin:10px 0;
    margin-top: 15px;
    color: black;
    min-height: 50px;
}
.story-image{
    width: 100%;
    background: #ffffff;
    position: relative;
}
.story-synopsis-link{
    display: block;
    text-decoration: underline;
    color: #fb7669;
    font-size: .7vw;
    margin-bottom: 10px;
}
.story-by,.story-date,.story-views{
    color: #d0c3bb;
    font-size: .9vw;
}
.story-views{
    margin-top: 5px;
    font-size: .7vw;
}
.story-preview-wrap footer{
    color:#ffd25e;
}
.story-icon{
    margin-left: 5px;
}

.story-footer{
    padding-top: 20px;
}
.story-footer table{
    width: 100%;
    font-size: .7vw;
    color:rgba(115,115,115,.7);
    text-align: left;
}
.story-footer table img{
    width: 45%;
    display: block;
    margin: 0 auto;
}
.story-footer div{
    margin:0 auto;
    text-align: center;
}
.story-synopsis-preview{
    color:rgba(115,115,115,.7);
    height: 100px;
    overflow-y: hidden;
    word-break: break-word;
}
.story-synopsis-link-dummy{
    visibility: hidden;
}
.story-preview-wrap header a{
    text-decoration: none;
    outline: 0;
}
.story-preview-wrap header a img{
    outline: 0;
}
.story-preview-wrap header a:hover .story-title{
    color: #fb7669;
}
.story-item-col{
    position: relative;
}
.story-item-show-more{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fdf0e8;
    border-radius: 5px;
    font-size: 1.3vw;
    color: #fb7669;
    cursor: pointer;
}
.story-item-show-more div{
    width: 96%;
}
.story-item-show-more:hover div{
    opacity: .8;
}
.story-synopsis-link-mob{
    display: none;
}
.story-rect-image,.rect-preview{
    display: none !important;
}
.story-preview-results-wrap{
    display: none;
}
.slide-inner{
    position: relative;
}
.slide-front{
    position: relative;
    /*background: #fcf0e8;*/
}
.slide-back{
    position: absolute;
    width: 98%;
    height: 95%;
    z-index: -1;
    left:0;
    top:0;
    background: #fcf0e8;
    border-radius: 5px;
    /*padding: 10px 5px;*/
    /*padding-bottom: 15px;*/
    font-size: 2.9vw;
    text-align: left;
}
.slide-back-synopsis{
    position: absolute;
    width: 90%;
    left: 5%;
    top:25%;
    max-height: 69%;
    overflow-y: scroll;
    overflow-x: hidden;
    word-break: break-word;
}
.slide-back-title{
    margin: 10px auto;
    margin-top: 25px;
    text-align: center;
    font-size: 3.5vw;
}
.slide-back img{
    position: absolute;
    top:8px;
    right:8px;
    width: 14%;
}
.flip-show{
    -webkit-animation: flip-show .3s linear forwards;
    animation: flip-show .3s linear forwards;
    transform-style: preserve-3d;
    backface-visibility: hidden;
}
.flip-hide{
    -webkit-animation: flip-hide .3s linear forwards;
    animation: flip-hide .3s linear forwards;
    transform-style: preserve-3d;
    backface-visibility: hidden;
}
.slide-back{
    display: none;
}
.story-square-image{
    position: absolute;
    top:0;
    z-index: 100;
}
.square-preview{
    position: relative;
}
.single-story-writer-name{
    color: #fa7669;
    background: #efd0c2;
    border-radius: 100px;
    padding: 4px 8px;
    margin-left: 1%;
    margin-top: 5px;
    font-size: 3vw;
    width: 96% !important;
}
.single-story-writer-name span{
    position: relative;
    top:2px;
    font-size: 3vw;
}
.single-story-writer-name img{
    width: 20px;
    height: 20px;
    position: relative;
    margin-right: 5px;
    display: inline-block !important;
    object-fit: cover;
    border-radius: 100px;
}
.story-writers-wrap{
    width: 100%;
    margin-top: 3px;
}
.single-story-writer-name.hidden{
    opacity: 0;
}

@media only screen and (max-width: 768px) {
    .slide-back{
        display: block;
    }
    .square-preview{
        display: none;
    }
    .library-no-results{
        font-size: 4vw;
    }
    .story-title,.story-preview-results-title{
        font-size: 2.8vw;
        min-height: 50px;
        font-weight: 600;
        padding-left: 1px;
        margin-top: 5px;
    }
    .story-preview-wrap{
        padding: 5px;
        margin-bottom: 10px;
    }
    .library-search-results .story-preview-wrap{
        display: none;
    }
    .story-by,.story-synopsis-preview{
        display: none;
    }
    .story-synopsis-link{
        font-size: 1.5vw;
        padding-left: 5px;
    }
    .story-footer table img{
        width: 60%;
    }
    .story-footer{
        padding-top: 5px;
    }
    .story-footer table{
        font-size: 1.9vw;
    }
    .story-item-show-more{
        font-size: 3.5vw;
    }
    .story-synopsis-link-web{
        display: none;
    }
    .story-synopsis-link-mob{
        display: block;
        font-size: 2.7vw;
        padding-left: 1px;
    }
    .library-search-results .col-3{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .library-search-results .story-item-col{
        background: #fdf0e8;
        border-radius: 5px;
        padding: 5px !important;
        margin-bottom: 15px;
        display: inline-block;
        width: 100%;
    }
    .library-search-results .story-preview-wrap{
        flex: 0 0 33%;
        max-width: 33%;
        float: left;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .library-search-results .story-title,.library-search-results .story-synopsis-link{
        display: none;
    }
    .library-search-results .story-rect-image{
        display: block !important;
        margin-top: 5px;
    }
    .library-search-results .story-square-image{
        display: none;
    }
    .library-search-results .story-preview-results-wrap{
        flex: 0 0 100%;
        max-width: 100%;
        float: left;
        display: block;
    }
    .story-preview-results-wrap .col-4{
        padding: 0;
    }
    .story-preview-results-wrap .col-8{
        padding-right: 0;
    }
    .library-search-results .story-preview-results-title{
        font-size: 4.2vw;
        margin-bottom: 5px;
    }
    .library-search-results .story-footer{
        margin-top: 5px;
    }
    .library-search-results .story-preview-results-synopsis{
        font-size: 3.5vw;
        word-break: break-word;
    }
}

@media only screen and (max-width: 420px) {
    .story-title,.story-preview-results-title{
        font-size: 2.8vw;
        min-height: 30px;
    }
    .story-synopsis-link{
        font-size: 1.5vw;
    }
    .story-footer table{
        font-size: 2.2vw;
    }
    .story-item-show-more{
        font-size: 3.5vw;
    }
    .story-synopsis-link-mob{
        font-size: 2.7vw;
    }
    .library-search-results .story-preview-results-title{
        font-size: 4.2vw;
    }
    .library-search-results .story-preview-results-synopsis{
        font-size: 3.5vw;
    }
    .story-footer table img{
        width: 90%;
    }
    .story-footer{
        padding-top: 0px;
    }
}

@keyframes flip-show {
    from { transform: rotateY(-180deg); z-index: -10; }
    to { transform: rotateY(0deg); z-index: 1; }
}
@keyframes flip-hide {
    from { transform: rotateY(0deg); }
    to { transform: rotateY(-180deg); }
}
