.lib-genres-slider{
    margin: 20px auto;
    margin-top: 0;
}
.genre-item{
    display: inline-block;
    margin-right: 10px;
    font-size: .9vw;
    background: #efd0c2;
    color: #fa7669;
    padding: 8px 20px;
    border-radius: 100px;
    border: 3px solid #fffaf6;
    text-align: center;
    outline: 0 !important;
    cursor: pointer;
}
.genre-item:hover{
    opacity: .8;
}
.category-results-wrap{
    position: static !important;
}
.category-no-results .app-button{
    margin-top: 15px;
}

@media only screen and (max-width: 768px) {
    .category-title .lib-genres-slider{
        display: block;
    }
    .genre-item{
        font-size: 2.3vw;
    }
    .library-search-results{
        min-height: auto;
    }
}
